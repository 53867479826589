import React from 'react'

const Video = ({ heading, lgwidth, url }) => {
    return (
        <div className={`flex flex-col gap-[10px] md:${lgwidth} w-11/12 h-fit justify-center items-center`}>
            <p className='franklinNine text-wrap text-[#E1FF00] font-normal text-[11.3px] md:text-[18.1px] w-1/2 text-center '>{heading}</p>

            <iframe className='w-full aspect-video' src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>

    )
}

export default Video
