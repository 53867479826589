import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

const AnswerQuestions = () => {
    const { user } = useContext(UserContext);
    const [questionData, setQuestionData] = useState(null);
    const [message, setMessage] = useState('Answer the follwing questions');
    const [answers, setAnswers] = useState([]);
    const navigte = useNavigate();
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_GET_ALL_QUESTIONS)
            .then(res => {
                console.log(res.data.data);
                setQuestionData(res.data.data);
                setAnswers(res.data.data.map(item => ({ question: item.question, answers: "" })));
            }).catch(err => {
                console.log("Error Displaying questions : ", err)
            })
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(answers);
        axios.put(process.env.REACT_APP_API_SUBMIT_ANSWERS, { userId: user.userId, answers: answers })
            .then(res => {
                setMessage(res.data.message);
                navigte('/dashboard')
            }).catch(err => {
                console.log("Error submiting answers : ", err)
                setMessage("Issue submitting answers")
            })

    }
    const handleInputChange = (e, index) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index].answers = e.target.value;
        setAnswers(updatedAnswers);

    }
    return (
        <div className="w-full max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold text-purple-700 mb-6 text-center">
                {message}
            </h1>

            <p className="text-lg text-gray-600 mb-6 text-center">
                Hello, please answer the following questions
            </p>

            <form onSubmit={handleSubmit} className="space-y-6">
                {questionData && questionData.map((item, index) => (
                    <div key={index} className="flex flex-col space-y-2">
                        <label
                            htmlFor={`question-${index}`}
                            className="text-md font-medium text-gray-700"
                        >
                            Q.{index + 1} {item.question}
                        </label>
                        <input
                            type="text"
                            required
                            placeholder={item.question}
                            id={`question-${index}`}
                            value={answers[index].answers}
                            onChange={(e) => handleInputChange(e, index)}
                            className="w-full px-4 py-2 border-2 border-purple-200 rounded-lg 
                         focus:outline-none focus:ring-2 focus:ring-purple-500 
                         transition-all duration-300 
                         placeholder-gray-400"
                        />
                    </div>
                ))}

                <div className="flex justify-center">
                    <button
                        type='submit'
                        className="w-full max-w-xs px-6 py-3 
                       bg-[#703BE7] text-white 
                       rounded-lg 
                       hover:bg-purple-700 
                       transition-colors 
                       duration-300 
                       focus:outline-none 
                       focus:ring-2 
                       focus:ring-purple-500 
                       focus:ring-opacity-50"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AnswerQuestions

