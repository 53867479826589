import React from 'react';

const Heading = ({ text, textStyles = [], margin }) => {
    return (
        <div className={`md:mt-[${margin}px] mt-[${margin - 50}px] flex flex-col justify-center items-center w-full h-fit`}>
            <h1 className='franklinNine text-white text-center md:text-[47.4px] font-normal md:w-4/5 w-11/12 text-[16.8px]'>
                {text.split(' ').map((word, index) => {
                    // Check if the word has a specific style in `textStyles`
                    const style = textStyles.find(({ word: styledWord }) => styledWord === word);

                    // Apply styles dynamically
                    return (
                        <span
                            key={index}
                            className={`
                                ${style?.color ? style.color : ''}
                                ${style?.italic ? 'italic' : ''}
                                ${style?.fontWeight ? style.fontWeight : ''}
                            `}
                        >
                            {word}{' '}
                        </span>
                    );
                })}
            </h1>
        </div>
    );
};

export default Heading;
