import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Offer from './Offer';

const LoadingLead = () => {
    const navigate = useNavigate();

    const [loadingDone, setLoadingDone] = useState(false)

    const [loader, setLoader] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setLoader((prev) => {
                if (prev >= 100) {
                    setLoadingDone(true);
                    localStorage.setItem('tj_loader', true)
                    clearInterval(interval);
                    return prev
                }
                return prev + 1;
            });
        }, 1000)
    }, [])

    useEffect(() => {
        setLoadingDone(localStorage.getItem('tj_loader'));
    }, [])

    const handleReward = () => {
        navigate('/rewards')
    }


    return (
        <div className='flex flex-col justify-center items-center bg-[#1E1E1E]'>


            {
                loadingDone ? (
                    <div className='md:w-3/5 w-11/12 flex flex-col md:gap-[29px] mt-[79px] justify-center items-center mb-2'>

                        <p className='franklinThree md:text-[29.3px] text-[13.77px] text-left w-full text-wrap text-white'><span className='text-[#00BA07] italic'>CONGRATULATIONS ..</span> you have UNLOCKED your free goodies click on <br /> <span className='text-[#00BA07]' >CLAIM REWARD</span> button at the end of this page</p>

                        {/* loader  */}
                        <div className='w-full border-2 border-[#00BA07] md:h-[58px] h-[36px] mt-4 overflow-hidden'>
                            <div style={{ width: `100%` }} className={`bg-[#00BA07] md:h-[58px] h-[36px]`}>
                            </div>
                        </div>
                        <div className='w-full bg-white h-[0.5px]'></div>
                    </div>
                    // <div>
                    //     <button onClick={handleReward}>Claim Rewards</button>
                    // </div>
                ) : (
                    <div className='md:w-3/5 w-11/12 flex flex-col md:gap-[29px] mt-[79px] justify-center items-center mb-2'>

                        <p className='franklinThree md:text-[29.3px] text-[13.77px] text-left w-full text-wrap text-white'>Your <span className='text-[#00BA07] italic'>₹31,884</span> worth of <span className='text-[#00BA07] italic' >FREE</span> stuff is loading please wait .. <span className='franklinNine text-[#F04037]'>DO NOT REFRESH THE PAGE</span></p>

                        {/* loader  */}
                        <div className='w-full border-2 border-[#00BA07] md:h-[58px] h-[36px] mt-4 overflow-hidden'>
                            <div style={{ width: `${loader}%` }} className={`bg-[#00BA07] md:h-[58px] h-[36px]`}>
                            </div>
                        </div>
                        <div className='w-full bg-white h-[0.5px]'></div>
                    </div>
                )
            }

            <Offer />

            {
                loadingDone ? (
                    <button onClick={handleReward} className='franklinThree md:text-[47.41px] text-[20px] flex justify-center items-center md:w-1/2 w-4/5 bg-[#00BA07] text-white md:pt-6 pt-3 md:pb-6 pb-3 mb-[100px]'>CLAIM REWARDS</button>
                ) : (
                    <div></div>
                )
            }


        </div>
    )
}

export default LoadingLead
