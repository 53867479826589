import React from 'react';
import Temp from '../../pages/Temp';
import { useParams } from 'react-router-dom';

const ClientChat = () => {
    const { id } = useParams();
    const roomId = Number(id);

    console.log("Room ID:", id); // Debugging Log
    return (
        <div className='w-full bg-white shadow-md rounded-lg p-6'>
            <div className='flex items-center justify-between border-b-2 border-[#703BE7] pb-4 mb-4'>
                <h1 className='text-3xl font-bold text-[#703BE7]'>Hello! Chat with Client</h1>
                <div className='bg-green-500 text-white px-4 py-2 rounded-full shadow-md'>
                    Room ID: <span className='font-bold ml-2'>{id}</span>
                </div>
            </div>
            <Temp room={roomId} />
        </div>
    );
};

export default ClientChat;
