import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'sonner';

const UpdatePackage = () => {
    const [packageData, setPackageData] = useState([]);
    const [prices, setPrices] = useState({});

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_GET_PACKAGE_DETAILS)
            .then(res => {
                console.log(res.data.message);
                console.log(res.data.data);
                setPackageData(res.data.data);

                // Initialize prices state with current package prices
                const initialPrices = {};
                res.data.data.forEach(item => {
                    initialPrices[item.package_id] = item.package_price;
                });
                setPrices(initialPrices);
            }).catch(err => {
                console.log("Error getting package data: ", err);
            });
    }, []);

    const handleUpdate = (packageId) => {
        axios.put(process.env.REACT_APP_API_UPDATE_PACKAGE, {
            packageId: packageId, // Fixed typo in 'packageId'
            price: prices[packageId]
        })
            .then(res => {
                toast.success(res.data.message);
            }).catch(err => {
                console.log("Error updating package: ", err);
            });
        window.location.reload();
    };

    const handlePriceChange = (packageId, newPrice) => {
        setPrices(prevPrices => ({
            ...prevPrices,
            [packageId]: newPrice
        }));
    };

    return (
        <div className="w-full max-w-6xl mx-auto p-4 sm:p-6 lg:p-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {packageData.map((item) => (
                    <div
                        key={item.package_id}
                        className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6 flex flex-col"
                    >
                        {/* Package Header */}
                        <div className="mb-4">
                            <h3 className="text-lg font-semibold text-gray-800 mb-1">
                                Package ID: {item.package_id}
                            </h3>
                            <h4 className="text-xl font-bold text-gray-900 mb-2">
                                {item.package_name}
                            </h4>
                            <div className="text-lg text-gray-700">
                                Current Price: ₹{item.package_price}
                            </div>
                        </div>

                        {/* Price Input */}
                        <div className="mt-auto">
                            <div className="mb-4">
                                <label
                                    htmlFor={`price-${item.package_id}`}
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                >
                                    New Price
                                </label>
                                <input
                                    id={`price-${item.package_id}`}
                                    type="text"
                                    value={prices[item.package_id] || ''}
                                    onChange={(e) => handlePriceChange(item.package_id, e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-colors duration-200"
                                    placeholder="Enter new price"
                                />
                            </div>

                            {/* Update Button */}
                            <button
                                onClick={() => handleUpdate(item.package_id)}
                                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                Update Price
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UpdatePackage;