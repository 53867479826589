import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { UserContext } from '../context/UserContext.js';
import { Menu, X } from 'lucide-react';
const Navbar = () => {

    const { user, logout } = useContext(UserContext);
    const [isOpen, setIsOpen] = useState(false);
    const token = localStorage.getItem('tj_token')

    return (


        <nav className="bg-gray-900 shadow-lg border-b border-[#00BA07]/20">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    {/* Logo/Brand section */}
                    <div className="flex-shrink-0">
                        <Link to="/" className="text-[#703BE7] font-bold text-xl">
                            TechJoy
                        </Link>
                    </div>

                    {/* Desktop Menu */}
                    <div className="hidden md:flex items-center space-x-4">
                        <Link to="/" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                            Home
                        </Link>
                        <Link to="/dashboard" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                            Dashboard
                        </Link>
                        <Link to="/blog" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                            Blog
                        </Link>
                        <Link to="/terms-and-conditions" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                            Terms & Conditions
                        </Link>


                        {/* Admin and Owner Links */}
                        {user && user.admin === 1 && (
                            <>
                                <Link to="/admin/orders" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                                    Orders
                                </Link>

                                {user.role === "owner" && (
                                    <>
                                        <Link to="/admin/packagedata" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                                            Update Package
                                        </Link>
                                        <Link to="/admin/completed/orderdata" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                                            Completed Orders
                                        </Link>
                                        <Link to="/admin/cancelled/orderdata" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                                            Cancelled Orders
                                        </Link>
                                        <Link to="/admin/users" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                                            User Data
                                        </Link>
                                        <Link to="/admin/questions" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                                            Questions
                                        </Link>
                                        <Link to="/admin/leads" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                                            Leads
                                        </Link>
                                        <Link to="/admin/funnel/orders" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                                            Funnel Order
                                        </Link>
                                        <Link to="/admin/orderdata" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                                            Order Table
                                        </Link>
                                    </>
                                )}
                            </>
                        )}





                        {
                            token ? (
                                <button
                                    onClick={() => logout()}
                                    className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors"
                                >
                                    Logout
                                </button>
                            ) : (
                                <div>
                                    <Link to="/login" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                                        Login
                                    </Link>
                                    <Link to="/signup" className="text-gray-300 hover:text-[#00BA07] px-3 py-2 rounded-md text-sm font-medium transition-colors">
                                        Register
                                    </Link>
                                </div>
                            )
                        }


                    </div>

                    {/* Mobile menu button */}
                    <div className="md:hidden flex items-center">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="text-gray-300 hover:text-[#00BA07] p-2"
                        >
                            {isOpen ? <X size={24} /> : <Menu size={24} />}
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <div className="md:hidden bg-gray-900 border-t border-[#00BA07]/20">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        <Link
                            to="/"
                            className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                            onClick={() => setIsOpen(false)}
                        >
                            Home
                        </Link>
                        <Link
                            to="/login"
                            className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                            onClick={() => setIsOpen(false)}
                        >
                            Login
                        </Link>
                        <Link
                            to="/signup"
                            className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                            onClick={() => setIsOpen(false)}
                        >
                            Register
                        </Link>
                        <Link
                            to="/dashboard"
                            className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                            onClick={() => setIsOpen(false)}
                        >
                            Dashboard
                        </Link>
                        <Link
                            to="/blog"
                            className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                            onClick={() => setIsOpen(false)}
                        >
                            Blog
                        </Link>
                        <Link
                            to="/terms-and-conditions"
                            className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                            onClick={() => setIsOpen(false)}
                        >
                            Terms & Conditions
                        </Link>

                        {/* Mobile Admin and Owner Links */}
                        {user && user.admin === 1 && (
                            <>
                                <Link
                                    to="/admin/orders"
                                    className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                                    onClick={() => setIsOpen(false)}
                                >
                                    Orders
                                </Link>

                                {user.role === "owner" && (
                                    <>
                                        <Link
                                            to="/admin/users"
                                            className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                                            onClick={() => setIsOpen(false)}
                                        >
                                            User Data
                                        </Link>
                                        <Link
                                            to="/admin/questions"
                                            className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                                            onClick={() => setIsOpen(false)}
                                        >
                                            Questions
                                        </Link>
                                        <Link
                                            to="/admin/leads"
                                            className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                                            onClick={() => setIsOpen(false)}
                                        >
                                            Leads
                                        </Link>
                                        <Link
                                            to="/admin/funnel/orders"
                                            className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                                            onClick={() => setIsOpen(false)}
                                        >
                                            Funnel Order
                                        </Link>
                                        <Link
                                            to="/admin/orderdata"
                                            className="text-gray-300 hover:text-[#00BA07] block px-3 py-2 rounded-md text-base font-medium transition-colors"
                                            onClick={() => setIsOpen(false)}
                                        >
                                            Order Table
                                        </Link>
                                    </>
                                )}
                            </>
                        )}

                        <button
                            onClick={() => {
                                logout();
                                setIsOpen(false);
                            }}
                            className="text-gray-300 hover:text-[#00BA07] block w-full text-left px-3 py-2 rounded-md text-base font-medium transition-colors"
                        >
                            Logout
                        </button>
                    </div>
                </div>
            )}
        </nav>
    )
}

export default Navbar
