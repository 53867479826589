import { createContext, useEffect, useState } from "react";
import { jwtDecode } from 'jwt-decode'; // Ensure correct import

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('tj_token');
        if (token) {
            const decodedToken = jwtDecode(token);
            setUser(decodedToken);
            console.log(decodedToken);
            // Check if the admin property is set to 1 and update the admin state accordingly
        } else {
            setUser(null);
        }
    }, []);

    const logout = () => {
        localStorage.removeItem('tj_token');
        window.location.reload();
    }


    return (
        <UserContext.Provider value={{ user, setUser, logout }}>
            {children}
        </UserContext.Provider>
    );
};
