import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { UserContext } from '../../context/UserContext';
import axios from 'axios';
import { toast } from 'sonner';
let socket;

const AllChat = () => {
    const { id } = useParams();
    const [message, setMessage] = useState('');
    const [messageList, setMessageList] = useState([]);
    const { user } = useContext(UserContext);
    const name = user.email;

    const chatBoxRef = useRef(null);

    // Scroll to the bottom when a new message is added
    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messageList]);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_GET_ALL_CHAT, { params: { id: id } })
            .then((res) => {
                console.log(res.data.data);
                setMessageList(res.data.data || []);
            })
            .catch((err) => {
                console.log('Error getting previous chats : ', err);
                toast.error('Error getting previous chats');
            });

        if (socket) {
            socket.disconnect();
        }

        socket = io(process.env.REACT_APP_SOCKET_URL);
        socket.emit('join_room', `allchat`);

        const handleMessage = (data) => {
            console.log('Message Received : ', data);
            setMessageList((prevMessages) => [...prevMessages, data]);
        };

        socket.on('receive_message', handleMessage);

        return () => {
            socket.off('receive_message', handleMessage);
            socket.disconnect();
        };
    }, [id]);

    const sendMessage = () => {
        if (message.trim() === '') return; // Prevent sending empty messages

        const time = `${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}`;
        const messageData = {
            room: `allchat`,
            author: name,
            message: message,
            time: time,
        };

        if (socket && socket.connected) {
            socket.emit('send_message', messageData);

            // No need to manually append the message here.
            // It will be received via the 'receive_message' socket event.
            setMessageList(prevMessages => [...prevMessages, messageData])

            axios
                .post(process.env.REACT_APP_API_SAVE_ALL_CHAT, { messageObj: messageData, id: Number(id) })
                .then((res) => {
                    console.log('Message saved successfully');
                    setMessage(''); // Clear input field
                })
                .catch((err) => {
                    console.log('Error saving message data', err.response?.data || err.message);
                    toast.error('Error saving message data');
                });
        }
    };

    return (
        <div className='flex flex-col gap-5 p-4 md:p-8 max-w-4xl mx-auto'>
            <div ref={chatBoxRef} className='flex flex-col w-full h-[500px] md:h-[672px] overflow-y-auto bg-white rounded-lg shadow-md border border-gray-100 p-4'>
                {messageList.map((messageContent, index) => (
                    <div
                        key={index}
                        className={`flex w-full max-w-[70%] flex-col gap-1 mb-3 ${name === messageContent.author ? 'self-end items-end ml-auto' : 'self-start items-start mr-auto'
                            }`}
                    >
                        <p
                            className={`text-white w-fit max-w-full break-words p-3 text-base md:text-lg rounded-lg shadow-sm ${name === messageContent.author ? 'bg-[#703BE7]' : 'bg-[#00BA07] text-white'
                                }`}
                        >
                            {messageContent.message}
                        </p>
                        <p
                            className={`text-xs text-gray-500 ${name === messageContent.author ? 'text-[#703BE7]' : 'text-[#00BA07]'
                                }`}
                        >
                            {messageContent.time} {messageContent.author}
                        </p>
                    </div>
                ))}
            </div>

            {/* Send message */}
            <div className='flex flex-col md:flex-row gap-4 items-center'>
                <input
                    className='w-full md:w-3/4 h-16 md:h-20 rounded-lg px-4 py-2 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#703BE7]'
                    type='text'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder='Enter your message'
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') sendMessage();
                    }}
                />
                <button
                    onClick={sendMessage}
                    className='w-full md:w-[100px] h-16 md:h-20 bg-[#00BA07] text-white rounded-lg hover:bg-green-600 transition-colors'
                >
                    Send
                </button>
            </div>
        </div>
    );
};

export default AllChat;
