import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const BlogDetail = () => {
    const [blog, setBlog] = useState(null); // Initialize as null (not an array)
    const [blogData, setBlogData] = useState([]); // Initialize as empty array (or null if required)
    const { id } = useParams();

    useEffect(() => {
        axios.get('http://localhost:8080/api/singleblog', { params: { id: id } })
            .then(res => {
                console.log(res.data.data);
                setBlog(res.data.data);

                if (res.data.data && res.data.data.blog_data) {
                    const parsedData = JSON.parse(res.data.data.blog_data); // Parse JSON correctly
                    setBlogData(parsedData);
                }
            })
            .catch(err => {
                console.log("Error getting single blog:", err);
            });
    }, [id]);

    // Ensure that blog and blogData exist before rendering
    if (!blog || blogData.length === 0) {
        return <div>Loading...</div>; // Display a loading state
    }

    return (
        <div className='w-full h-fit bgPurple flex flex-col justify-center items-center md:pt-[152px]'>
            {/* Blog container */}
            <div className='flex flex-col justify-center items-start md:max-w-[1200px] w-full bg-black md:pl-[100px] pl-[22.37px] pt-[33px] pb-[250px]'>
                <h1 className='text-white franklinNine md:text-[71.11px] text-[26.67px]'>{blog.blog_heading}</h1>

                {blogData && blogData.map((item, index) => (
                    <div key={index} className='flex flex-col justify-center items-start w-11/12 h-fit md:gap-[60px] gap-[22.5px] md:mt-[200px] mt-[70px]'>
                        <h2 className='text-white md:text-[43.94px] text-[16.48px] franklinThree w-full'>
                            {index + 1}. {item.heading}
                        </h2>

                        <div className='max-w-[473px] w-1/2 h-fit flex justify-center items-center overflow-hidden md:rounded-[23px] rounded-[8.62px]'>
                            <img className='scale-100' src={`http://localhost:8080/uploads/${item.image}`} alt={item.heading} />
                        </div>

                        <ol className='w-11/12 h-fit text-white list-decimal text-[10.19px] md:text-[27.17px] franklinOne'>
                            {item.details && item.details.split(".").map((detail, i) => (
                                <li key={i} className='md:pb-8 pb-4'>{detail.trim()}</li>
                            ))}
                        </ol>
                    </div>
                ))}

                {/* Sub heading with image and content */}
                <h3 className='text-white mt-[50px] md:text-[20px] text-[11px]'>
                    You know what? Just by clicking on
                    <a className='text-blue-600 underline' href="http://techjoy.in"> techjoy.in </a>
                    you can completely AUTOMATE your business for <span className='text-green-500'>FREE</span>
                </h3>
            </div>
        </div>
    );
};

export default BlogDetail;
