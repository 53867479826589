import React from 'react';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
    const steps = [
        {
            "heading": "1. Payment and Project Initiation",
            "points": [
                "The client will make the full payment for the selected package before the project begins.",
                "After payment confirmation, an online or offline meeting will be scheduled with the client to discuss and gather project requirements."
            ]
        },
        {
            "heading": "2. Design Process",
            "points": [
                "After gathering the requirements, a custom-designed home page will be created and sent to the client for approval.",
                "If the home page design is approved, the rest of the website design will be initiated.",
                "The complete design process, which includes regular follow-ups with the client, will be completed within the first week.",
                "If the design is not approved, revisions will be made until the client is satisfied with the home page design."
            ]
        },
        {
            "heading": "3. Development Preparation",
            "points": [
                "Once the design is approved, a document containing a list of required information (such as Instagram links, ID, password, etc.) will be sent to the client.",
                "The client must provide the requested details promptly to continue the project."
            ]
        },
        {
            "heading": "4. Design Completion and Review",
            "points": [
                "Upon receiving all the necessary details, the remaining parts of the website will be designed.",
                "After the design is completed, the client will be asked for a review.",
                "Any changes or adjustments requested by the client will be made until the client is fully satisfied with the design."
            ]
        },
        {
            "heading": "5. Development Process",
            "points": [
                "Once the client approves the final design, the development phase will begin.",
                "After the development is completed, the client will be asked to provide hosting and domain charges."
            ]
        },
        {
            "heading": "6. Website Upload",
            "points": [
                "Upon receiving the hosting and domain charges, the website will be uploaded and made live."
            ]
        }
    ];

    const policy = [
        {
            "heading": "1. 100% Money-Back Guarantee (Before Home Page Approval)",
            "points": [
                "If the client cancels the project before approving the home page design, they will receive a full refund of 100% of the amount paid."
            ]
        },
        {
            "heading": "2. 15% Deduction (After Home Page Approval)",
            "points": [
                "If the client cancels the project after approving the home page design but before the complete design is finalized, 15% of the total amount will be deducted, and the rest will be refunded."
            ]
        },
        {
            "heading": "3. 30% Deduction (After Design Completion)",
            "points": [
                "If the client cancels the project after the entire design process is completed, 30% of the total amount will be deducted, and the remainder will be refunded."
            ]
        },
        {
            "heading": "4. 65% Deduction (After Development Begins)",
            "points": [
                "If the client cancels the project after the development phase has started, 65% of the total amount will be deducted, and the remaining amount will be refunded."
            ]
        },
        {
            "heading": "5. No Refund (After Development Completion)",
            "points": [
                "If the client cancels the project after the entire development process is completed and before the website is uploaded, no refund will be issued, and 100% of the total amount will be retained."
            ]
        },
        {
            "heading": "6. Only two design revision for each page while designing",
            "points": [
                "For example if the the designing is going of Home page and the client says to modify something he/she can only perform two change on home page ( maximum of 2 changes on the design of every page )"
            ]
        },
    ];


    return (
        <>
            <Helmet>
                {/* Page Title */}
                <title>How TechJoy Works | Our Web, App & Software Development Process</title>

                {/* Meta Description */}
                <meta
                    name="description"
                    content="Discover TechJoy's streamlined development process for web, app, and software projects. From payment initiation to project completion, learn how we ensure quality and client satisfaction."
                />

                {/* Meta Keywords */}
                <meta
                    name="keywords"
                    content="TechJoy, development process, web development steps, app development process, software development lifecycle, project initiation, design process, development preparation, website upload, refund policy, tech services, IT consulting, software solutions"
                />

                {/* Canonical URL */}
                <link rel="canonical" href="https://techjoy.in/process" />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="How TechJoy Works | Our Web, App & Software Development Process" />
                <meta
                    property="og:description"
                    content="Explore TechJoy's comprehensive development process for web, app, and software projects. Learn about our steps from payment initiation to project completion and our client-friendly refund policies."
                />
                <meta property="og:url" content="https://techjoy.in/process" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://techjoy.in/public/process-image.jpg" /> {/* Update with an appropriate image */}
                <meta property="og:site_name" content="TechJoy" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:see_also" content="https://www.facebook.com/Techjoy" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How TechJoy Works | Our Web, App & Software Development Process" />
                <meta
                    name="twitter:description"
                    content="Understand TechJoy's step-by-step development process for web, app, and software projects. From initial payment to project delivery, our process ensures quality and client satisfaction."
                />
                <meta name="twitter:image" content="https://techjoy.in/public/process-image.jpg" /> {/* Update with an appropriate image */}

                {/* Alternate Language and Regional URLs */}
                {[
                    'en-us',
                    'en-gb',
                    'en-in',
                    'en-au',
                    'en-ca',
                    'en-nz',
                    'en-sg',
                    'en-za',
                    'en-ie',
                    'en-ph',
                    'en-hk',
                    'en-mt',
                    'en-ke',
                    'en-nl',
                    'en-ug',
                    'en-gh',
                    'en-tz',
                    'en-ae',
                    'en-jp',
                    'en-eg',
                    'en-dz',
                    'en-om',
                    'en-qa',
                    'en-sa',
                    'en-jo',
                    'en-ma',
                    'en-ye',
                    'en-tn',
                    'en-bd',
                    'en-lk',
                    'en-mu',
                    'en-pk',
                    'en-my',
                    'en-id',
                    'en-vn',
                    'en-th',
                    'en-kr',
                    'en-cn',
                    'en-tw',
                    'en-az',
                    'en-kz',
                    'en-uz',
                    'en-tm',
                    'en-kg',
                    'en-ge',
                    'en-am',
                    'en-tr',
                    'en-ir',
                    'en-il',
                    'en-ps',
                ].map((lang) => (
                    <link key={lang} rel="alternate" href="https://techjoy.in/process" hreflang={lang} />
                ))}

                {/* Structured Data (JSON-LD) */}
                <script type="application/ld+json">
                    {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "How TechJoy Works",
          "url": "https://techjoy.in/process",
          "description": "Learn about TechJoy's comprehensive development process for web, app, and software projects, including payment initiation, design, development, and refund policies.",
          "publisher": {
            "@type": "Organization",
            "name": "TechJoy",
            "url": "https://techjoy.in",
            "logo": {
              "@type": "ImageObject",
              "url": "https://techjoy.in/public/favicon.ico"
            },
            "sameAs": [
              "https://www.instagram.com/TechJoy.in_/",
              "https://www.facebook.com/Techjoy"
            ]
          },
          "mainEntity": {
            "@type": "HowTo",
            "name": "TechJoy Development Process",
            "step": [
              {
                "@type": "HowToStep",
                "name": "Payment and Project Initiation",
                "url": "https://techjoy.in/process#payment-and-project-initiation",
                "description": "Clients make full payment for the selected package. Post payment confirmation, an online or offline meeting is scheduled to discuss project requirements."
              },
              {
                "@type": "HowToStep",
                "name": "Design Process",
                "url": "https://techjoy.in/process#design-process",
                "description": "A custom-designed home page is created for client approval. Upon approval, the rest of the website design commences, with the design process completed within the first week."
              },
              {
                "@type": "HowToStep",
                "name": "Development Preparation",
                "url": "https://techjoy.in/process#development-preparation",
                "description": "A document listing required information is sent to the client. The client must provide these details promptly to proceed with the project."
              },
              {
                "@type": "HowToStep",
                "name": "Design Completion and Review",
                "url": "https://techjoy.in/process#design-completion-and-review",
                "description": "Remaining parts of the website are designed. The client reviews the design, and adjustments are made until full satisfaction."
              },
              {
                "@type": "HowToStep",
                "name": "Development Process",
                "url": "https://techjoy.in/process#development-process",
                "description": "Upon final design approval, the development phase begins. After completion, clients are asked to provide hosting and domain charges."
              },
              {
                "@type": "HowToStep",
                "name": "Website Upload",
                "url": "https://techjoy.in/process#website-upload",
                "description": "Upon receiving hosting and domain charges, the website is uploaded and made live."
              },
              {
                "@type": "HowToStep",
                "name": "Cancellation and Refund Policy",
                "url": "https://techjoy.in/process#cancellation-and-refund-policy",
                "description": "Details of the refund policy based on project cancellation stages."
              }
            ]
          }
        }
      `}
                </script>
            </Helmet>
            <div className='flex flex-col justify-end items-end w-full h-fit mt-[144px]'>
                <div className='md:w-3/4 w-11/12 h-fit flex flex-col justify-start items-start gap-[100px]'>

                    <h1 className='text-white underline pb-3 underline-offset-[12px] md:text-5xl text-[18.34px]'>
                        How Techjoy Works
                    </h1>

                    {/* Step content */}

                    {
                        steps.map((item, index) => (
                            <div key={index} className='flex flex-col gap-6'>
                                <h1 className='text-white underline pb-3 underline-offset-[12px] md:text-[29.67px] text-[18.34px]'>
                                    {item.heading}
                                </h1>

                                <ul className='list-disc'>
                                    {
                                        item.points.map((point) => (
                                            <li className='text-white md:text-[18.34px] text-[11.33px] w-11/12'>
                                                {point}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        ))
                    }

                    <h2 className='text-white underline pb-3 underline-offset-[12px] md:text-5xl text-[18.34px]'>
                        Cancellation and Refund Policy
                    </h2>

                    {
                        policy.map((item, index) => (
                            <div key={index} className='flex flex-col gap-6'>
                                <h1 className='text-white underline pb-3 underline-offset-[12px] md:text-[29.67px] text-[18.34px]'>
                                    {item.heading}
                                </h1>

                                <ul className='list-disc'>
                                    {
                                        item.points.map((point) => (
                                            <li className='text-white md:text-[18.34px] text-[11.33px] w-11/12'>
                                                {point}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
};

export default TermsAndConditions;
