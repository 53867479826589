import React, { useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import Chat from '../Component/Chat';
import { UserContext } from '../context/UserContext';

let socket; // Declare socket outside to persist connection across renders

const Temp = (props) => {
    const [isJoined, setIsJoined] = useState(false); // Track if the user joined a room
    const { user } = useContext(UserContext);
    const name = user.email;
    // const room = user.userId;
    const room = props.room;


    useEffect(() => {
        if (name && room) {
            socket = io(process.env.REACT_APP_SOCKET_URL); // Initialize socket connection
            socket.emit('join_room', room);
            setIsJoined(true);

            return () => {
                // Clean up the socket connection to avoid memory leaks
                if (socket) {
                    socket.disconnect();
                }
            };
        }
    }, [name, room]); // Dependencies ensure this runs once when name/room are available

    return (
        <div className="w-full">
            {isJoined && (
                <div className="w-full">
                    <Chat socket={socket} name={name} room={room} />
                </div>
            )}
        </div>

    );
};

export default Temp;
