import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Heading from '../../Component/Heading';
import Video from '../../Component/Video';
import { Helmet } from 'react-helmet'

const LeadSqueeze = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const [isEmailSent, setIsEmailSent] = useState(false);

    const handleEmail = () => {
        axios.post(process.env.REACT_APP_API_CHECK_OTP_SEND_EMAIL, { email: email })
            .then(res => {
                setIsEmailSent(true);
                alert("OTP is send on ", email);
            }).catch(err => {
                console.log("Error sending otp mail", err)
            })
    }
    const handleOtp = () => {
        axios.post(process.env.REACT_APP_API_CHECK_OTP, { OneTimePassword: otp })
            .then(res => {
                localStorage.setItem('reward_acces', true);
                localStorage.setItem('tj_email', res.data.data);
                alert("OTP matched succesfully");
                navigate(`/loading/${otp}`)
            }).catch(err => {
                alert("You have entered wrong code ")
            })
    }

    const text =
        " You can laugh at people who are working long hours on their business and still not getting any results ";

    const textStyles = [
        { word: 'You', color: 'text-green-500', italic: false },
        { word: 'can', color: 'text-green-500', italic: false },
        { word: 'laugh', color: 'text-green-500', italic: false },
        { word: 'long', color: 'text-red-500', italic: false },
        { word: 'hours', color: 'text-red-500', italic: false },
        { word: 'results', color: 'text-red-500', italic: false },
        // { word: 'working', color: 'text-blue-500', fontWeight: 'font-thin' },
        { word: 'results', color: 'text-green-500', italic: true, fontWeight: 'font-thin' },
    ];

    const offerItems = [
        { title: `Video guide on automating complex business`, price: "10,000" },
        { title: `Video guide on automating simple business`, price: "2,000" },
        { title: `1 hour of live private consultation`, price: "2,596" },
    ];

    return (
        <>
            <Helmet>
                {/* Title */}
                <title>Automate Your Business for FREE – Get Video Guides & Live Consultation! 🚀</title>

                {/* Meta Description */}
                <meta
                    name="description"
                    content="Struggling to manage your business? Learn how to fully automate your operations with our FREE video guides & a 1-hour live consultation. Get started today!"
                />

                {/* Keywords (SEO-Optimized) */}
                <meta
                    name="keywords"
                    content="business automation, automate my business, free business automation guide, CRM automation, small business automation, workflow automation, business growth secrets, scale business without extra effort, business efficiency, AI business automation"
                />

                {/* Open Graph for Facebook, LinkedIn, WhatsApp */}
                <meta property="og:title" content="Automate Your Business for FREE – Get Video Guides & Live Consultation!" />
                <meta property="og:description" content="Get a FREE video guide + a 1-hour private consultation on how to automate your business. Enter your email & unlock the secrets now!" />
                <meta property="og:image" content="https://techjoy.in/path-to-thumbnail.jpg" />
                <meta property="og:url" content="https://techjoy.in/" />
                <meta property="og:type" content="website" />

                {/* Twitter Card for Better Social Visibility */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Automate Your Business for FREE – Get Video Guides & Live Consultation!" />
                <meta name="twitter:description" content="Want to automate your business? Get a FREE video guide + a 1-hour live consultation. No cost, just results!" />
                <meta name="twitter:image" content="https://techjoy.in/path-to-thumbnail.jpg" />

                {/* Canonical URL (Avoid Duplicate Content Issues) */}
                <link rel="canonical" href="https://techjoy.in/" />

                {/* Additional Schema Markup for Google Rich Results */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "TechJoy – Automate Your Business for Free",
                        "description": "Struggling to manage your business? Get FREE video guides & a 1-hour live consultation on business automation. Unlock the secrets today!",
                        "url": "https://techjoy.in/",
                        "publisher": {
                            "@type": "Organization",
                            "name": "TechJoy",
                            "url": "https://techjoy.in/"
                        },
                        "mainEntity": {
                            "@type": "Offer",
                            "name": "Free Business Automation Video Guide & Live Consultation",
                            "description": "Learn the secrets to automating any business for free. Get exclusive video guides and a 1-hour private consultation!",
                            "price": "0",
                            "priceCurrency": "INR",
                            "validThrough": "2025-12-31"
                        }
                    })}
                </script>
            </Helmet>
            <div className='bg-[#1E1E1E] flex flex-col justify-center items-center'>

                <Heading text={text} textStyles={textStyles} margin={100} />

                <svg className='md:mt-[100px] mt-[50px]' width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.6542 45.0135L33.9346 35.2887C34.7375 34.5291 34.7727 33.2624 34.0132 32.4595C33.2537 31.6566 31.9869 31.6214 31.184 32.3809L24.4136 38.7855L25.3905 3.60452C25.4212 2.49973 24.5504 1.57914 23.4455 1.54846C22.3408 1.51778 21.4202 2.38862 21.3895 3.49341L20.4125 38.6744L14.0079 31.9038C13.2483 31.1009 11.9816 31.0657 11.1787 31.8253C10.3758 32.5848 10.3406 33.8515 11.1001 34.6544L20.825 44.9348C21.5846 45.7379 22.8513 45.773 23.6542 45.0135Z" fill="#E1FF00" />
                </svg>



                {/* main div  */}
                <div className='flex lg:flex-row flex-col flex-wrap justify-evenly items-center w-full h-fit lg:gap-0 gap-[50px] lg:mt-[100px] mt-[45px]'>

                    <Video url="https://www.youtube.com/embed/8VWL-qNwzgg?si=sNhmfSbZfNm2K78Q" heading="Watch this video on how to UNLOCK THESE SECRETS" lgwidth="w-2/5" />

                    {/* text part  */}
                    <div className='flex flex-col justify-center items-start md:gap-[21px] gap-3 lg:w-2/5 w-11/12 h-fit'>
                        <h2 className='franklinNine w-full text-[#E1FF00] font-normal text-[13.51px] md:text-[23.97px]'>IF YOU FOLLOW THIS SIMPLE PLAN</h2>

                        <p className='franklinNine md:text-[21.68px] text-[12.2px] text-white tracking-wide'>1. <span className='text-[#00BA07]'>FREE</span> video guide, revealing the secrets to automate a complex business</p>

                        <p className='franklinNine md:text-[21.68px] text-[12.2px] text-white tracking-wide'>1. <span className='text-[#00BA07]'>FREE</span> video guide, secrets to automate a simple business</p>
                        <div className='w-11/12 bg-[#E1FF00] h-[0.5px]'></div>

                        <p className='franklinNine md:text-[21.68px] text-[12.2px] text-white tracking-wide'>1. <span className='text-[#00BA07]'>FREE</span> 1:1 (one on one) Live 1 HOUR consultation on how your business can be automated</p>

                    </div>
                </div>


                <svg className='md:mt-[100px] mt-[50px]' width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.6542 45.0135L33.9346 35.2887C34.7375 34.5291 34.7727 33.2624 34.0132 32.4595C33.2537 31.6566 31.9869 31.6214 31.184 32.3809L24.4136 38.7855L25.3905 3.60452C25.4212 2.49973 24.5504 1.57914 23.4455 1.54846C22.3408 1.51778 21.4202 2.38862 21.3895 3.49341L20.4125 38.6744L14.0079 31.9038C13.2483 31.1009 11.9816 31.0657 11.1787 31.8253C10.3758 32.5848 10.3406 33.8515 11.1001 34.6544L20.825 44.9348C21.5846 45.7379 22.8513 45.773 23.6542 45.0135Z" fill="#E1FF00" />
                </svg>

                {/* hardest value display  */}
                <div className='md:w-4/5 w-11/12 h-fit flex flex-col justify-center items-center bg-white md:mt-[100px] mt-[50px]'>

                    {/* green div  */}
                    <div className='w-full md:h-[220px] h-[103.36px] bg-[#00BA07] flex flex-col justify-center items-center mb-5'>

                        <p className='text-white md:text-[29.3px] text-[11.19px] text-center md:w-2/3 w-11/12 franklinNine'>
                            Let me show you <span className='italic'>EVERYTHING</span> that you will get, when you only just put YOUR Email address HERE
                        </p>
                    </div>

                    {/* list div  */}
                    <div className='flex flex-col justify-center items-center w-full h-fit flex-nowrap md:gap-[21px] gap-[8.4px]
                '>

                        {
                            offerItems && offerItems.map((item, index) => (
                                //single offer item
                                <div className='flex flex-row justify-center items-start w-full gap-[28px]'>

                                    <div className='flex flex-row justify-center items-center md:gap-2 gap-1 w-[70%]'>
                                        <svg
                                            className="w-[12.69px] h-[12.69px] md:w-[27px] md:h-[27px]"
                                            viewBox="0 0 27 27"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_175_308)">
                                                <path
                                                    d="M25.2052 1.1498L24.7945 0.864753L24.5094 1.27534L10.7985 21.018L1.91659 13.7668L1.52918 13.4505L1.21299 13.838L-0.387396 15.7993L-0.703421 16.1866L-0.316209 16.5027L11.1039 25.8262L11.5225 26.168L11.8307 25.7241L27.4107 3.28953L27.6959 2.87876L27.2851 2.59359L25.2052 1.1498Z"
                                                    fill="#00BA07"
                                                    stroke="#00BA07"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_175_308">
                                                    <rect width="27" height="27" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>


                                        <p className='text-[#636363] md:text-[29.3px] text-[11.19px] text-left md:w-1/2 w-[80%] franklinOne text-wrap'>{item.title}</p>
                                    </div>

                                    <p className='text-[#F04037] md:text-[29.3px] text-[11.19px] text-left w-[30%] franklinNine text-nowrap'>( ₹{item.price} Value )</p>

                                </div>
                            ))
                        }

                    </div>

                    <p className='franklinNine text-[22.27px] md:text-[47.41px]  md:mt-[74px] mt-[34.24px] tracking-wider text-[#F04037]'><span className='italic text-black'>Total Value : </span> ₹14,596 </p>

                    <p className='franklineThree text-[#1E1E1E] text-[22.27px] md:text-[47.41px] md:mt-5 mt-2 w-full text-center'>get all this TODAY for</p>

                    <p className='franklinNine text-[#00BA07] text-[122.73px] md:text-[303.79px] text-nowrap text-center italic'>FREE</p>
                </div>

                <svg className='md:mt-[100px] mt-[50px]' width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.6542 45.0135L33.9346 35.2887C34.7375 34.5291 34.7727 33.2624 34.0132 32.4595C33.2537 31.6566 31.9869 31.6214 31.184 32.3809L24.4136 38.7855L25.3905 3.60452C25.4212 2.49973 24.5504 1.57914 23.4455 1.54846C22.3408 1.51778 21.4202 2.38862 21.3895 3.49341L20.4125 38.6744L14.0079 31.9038C13.2483 31.1009 11.9816 31.0657 11.1787 31.8253C10.3758 32.5848 10.3406 33.8515 11.1001 34.6544L20.825 44.9348C21.5846 45.7379 22.8513 45.773 23.6542 45.0135Z" fill="#E1FF00" />
                </svg>


                {
                    isEmailSent ? (
                        <div className='md:w-2/5 w-11/12 h-fit flex flex-col md:gap-[10px] gap-2 justify-center items-center md:mt-[100px] mt-[50px] mb-[100px]'>
                            <h3 className='franklinNine w-full text-[#E1FF00] font-normal text-[13.51px] md:text-[23.97px] text-center'>Enter OTP sent to {email}</h3>
                            <input className='w-full md:h-[77px] h-10 bg-white md:rounded-2xl rounded-lg md:text-[29.29px] text-[15.18px] text-[#3b3b3b] franklinNine outline-none border-none pl-3' placeholder='Enter OTP' type="text" value={otp} onChange={(e) => setOtp(e.target.value)} name="" id="" />
                            <button onClick={handleOtp} className='franklinNine md:text-[29.29px] text-[15.18px] text-white text-wrap bg-[#00BA07] md:rounded-2xl rounded-lg w-full pl-2 pr-2'>Submit <br /> OTP</button>
                        </div>
                    ) : (
                        <div className='md:w-2/5 w-11/12 h-fit flex flex-col md:gap-[10px] gap-2 justify-center items-center md:mt-[100px] mt-[50px] mb-[100px]'>
                            <h3 className='franklinNine w-full text-[#E1FF00] font-normal text-[13.51px] md:text-[23.97px] text-center'>Enter your EMAIL to unlock secrets</h3>

                            <input className='w-full md:h-[77px] h-10 bg-white md:rounded-2xl rounded-lg md:text-[29.29px] text-[15.18px] text-[#3b3b3b] franklinNine outline-none border-none pl-3' placeholder='Enter your email here' type="email" name="" id="" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <button onClick={handleEmail} className='franklinNine md:text-[29.29px] text-[15.18px] text-white text-wrap bg-[#00BA07] md:rounded-2xl rounded-lg w-full pl-2 pr-2'>Yes, I want to unlock the secrets to automate my business for FREE</button>
                        </div>
                    )
                }



            </div>
        </>

    )
}

export default LeadSqueeze
