import axios from 'axios';
import React, { useEffect, useState } from 'react'

const Users = () => {
    const [userData, setUserData] = useState([]);
    const [isStaff, setIsStaff] = useState(false);
    //states for updating
    const [adminStatus, setAdminStatus] = useState({});
    const [role, setRole] = useState({});

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_GET_USERS)
            .then(res => {
                console.log(res.data.data);
                setUserData(res.data.data)
            }).catch(err => {
                console.log("Error getting user data : ", err)
            })
    }, [])
    const updateUser = (userId, isAdmin, role) => {
        axios
            .put(process.env.REACT_APP_API_UPDATE_USER, { userId, isAdmin, role })
            .then((res) => {
                alert(res.data.message);
                window.location.reload();
            })
            .catch((err) => {
                console.log("Error updating user data", err);
            });
    };

    return (
        <div className="p-6 bg-white rounded-lg shadow-md">
            {isStaff ? (
                <div>
                    <button
                        onClick={() => setIsStaff(false)}
                        className="mb-4 px-4 py-2 text-white bg-green-600 rounded hover:bg-green-700 transition"
                    >
                        Show User Data
                    </button>
                    <div className="overflow-x-auto">
                        <table className="min-w-full table-auto border-collapse border border-gray-300">
                            <thead>
                                <tr className="bg-[#703BE7] text-white">
                                    <th className="px-4 py-2 border border-gray-300">User ID</th>
                                    <th className="px-4 py-2 border border-gray-300">Email</th>
                                    <th className="px-4 py-2 border border-gray-300">Admin</th>
                                    <th className="px-4 py-2 border border-gray-300">Role</th>
                                    <th className="px-4 py-2 border border-gray-300">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userData &&
                                    userData
                                        .filter((item) => item.isAdmin === 1)
                                        .map((item, index) => {
                                            return <tr
                                                key={index}
                                                className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"
                                                    }`}
                                            >
                                                <td className="px-4 py-2 border border-gray-300 text-center">
                                                    {item.user_id}
                                                </td>
                                                <td className="px-4 py-2 border border-gray-300 text-center">
                                                    {item.user_email}
                                                </td>
                                                <td className="px-4 py-2 border border-gray-300 text-center">
                                                    {item.isAdmin}
                                                </td>
                                                <input
                                                    onChange={(e) =>
                                                        setRole((prev) => ({
                                                            ...prev,
                                                            [item.user_id]: e.target.value,
                                                        }))
                                                    }
                                                    value={role[item.user_id]} // Use specific role value
                                                    placeholder={item.role}
                                                    className="px-4 py-2 border border-gray-300 text-center w-fit"
                                                />
                                                <td className="px-4 py-2 border border-gray-300 text-center">
                                                    <button className="mr-2 px-3 py-1 text-white bg-red-600 rounded hover:bg-red-700 transition">
                                                        Delete
                                                    </button>
                                                    <button onClick={() => updateUser(item.user_id, item.isAdmin, role[item.user_id] || item.role)} className="px-3 py-1 text-white bg-blue-600 rounded hover:bg-blue-700 transition">
                                                        Update
                                                    </button>
                                                </td>
                                            </tr>
                                        })}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div>
                    <button
                        onClick={() => setIsStaff(true)}
                        className="mb-4 px-4 py-2 text-white bg-green-600 rounded hover:bg-green-700 transition"
                    >
                        Show Staff Data
                    </button>
                    <div className="overflow-x-auto">
                        <table className="min-w-full table-auto border-collapse border border-gray-300">
                            <thead>
                                <tr className="bg-[#703BE7] text-white">
                                    <th className="px-4 py-2 border border-gray-300">User ID</th>
                                    <th className="px-4 py-2 border border-gray-300">Email</th>
                                    <th className="px-4 py-2 border border-gray-300">Admin</th>
                                    <th className="px-4 py-2 border border-gray-300">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userData &&
                                    userData.map((item, index) => (
                                        <tr
                                            key={index}
                                            className={`${index % 2 === 0 ? "bg-gray-100" : "bg-white"
                                                }`}
                                        >
                                            <td className="px-4 py-2 border border-gray-300 text-center">
                                                {item.user_id}
                                            </td>
                                            <td className="px-4 py-2 border border-gray-300 text-center">
                                                {item.user_email}
                                            </td>
                                            <input
                                                type="number"
                                                min="0"
                                                max="1"
                                                onChange={(e) =>
                                                    setAdminStatus((prev) => ({
                                                        ...prev,
                                                        [item.user_id]: Number(e.target.value),
                                                    }))
                                                }
                                                value={
                                                    adminStatus[item.user_id] !== undefined
                                                        ? adminStatus[item.user_id]
                                                        : item.isAdmin
                                                }
                                                placeholder={item.isAdmin.toString()}
                                                className="px-4 py-2 border border-gray-300 text-center w-fit"
                                            />
                                            <td className="px-4 py-2 border border-gray-300 text-center">
                                                <button className="mr-2 px-3 py-1 text-white bg-red-600 rounded hover:bg-red-700 transition">
                                                    Delete
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        updateUser(
                                                            item.user_id,
                                                            adminStatus[item.user_id] !== undefined
                                                                ? adminStatus[item.user_id]
                                                                : item.isAdmin,
                                                            item.role
                                                        )
                                                    }
                                                    className="px-3 py-1 text-white bg-blue-600 rounded hover:bg-blue-700 transition"
                                                >
                                                    Update
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>


    )
}

export default Users
