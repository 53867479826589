import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'sonner';


const OrderTable = () => {
    const [orderData, setOrderData] = useState([]);

    const [userId, setUserId] = useState(0);
    const [packageId, setPackageId] = useState(0);
    const [packageName, setPackageName] = useState('');
    const [amountPaid, setAmountPaid] = useState('');
    const [isFormExpanded, setIsFormExpanded] = useState(false);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_SEE_ORDER)
            .then(res => {
                console.log(res.data.data);
                setOrderData(res.data.data);
            }).catch(err => {
                console.log("Error seeing order data : ", err);
                toast.error("Error Getting order data")
            })
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API_ADD_ORDER, { userId, packageId, packageName, amountPaid, })
            .then(res => {
                toast.success(res.data.message);
                window.location.reload();
            }).catch(err => {
                console.log("Error adding order data : ", err);
                toast.error("Error adding order data")
            })
    }


    return (
        <div className="min-h-screen bg-gradient-to-br from-purple-50 to-purple-100 p-4">
            <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                {/* Header */}
                <div className="bg-gradient-to-r from-purple-600 to-purple-800 p-4">
                    <h2 className="text-2xl font-bold text-white">Order Management</h2>
                </div>

                {/* Form Section */}
                <div className="p-6 bg-purple-50">
                    <div
                        className="flex justify-between items-center cursor-pointer mb-4"
                        onClick={() => setIsFormExpanded(!isFormExpanded)}
                    >
                        <h3 className="text-xl font-semibold text-purple-800">
                            Add New Order
                        </h3>
                        <button
                            className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
                        >
                            {isFormExpanded ? 'Collapse' : 'Expand'}
                        </button>
                    </div>

                    {isFormExpanded && (
                        <form
                            onSubmit={handleSubmit}
                            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"
                        >
                            <input
                                placeholder='User ID'
                                type="number"
                                value={userId}
                                onChange={(e) => setUserId(e.target.value)}
                                className="w-full px-3 py-2 border border-purple-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                            <input
                                placeholder='Package ID'
                                type="number"
                                value={packageId}
                                onChange={(e) => setPackageId(e.target.value)}
                                className="w-full px-3 py-2 border border-purple-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                            <input
                                placeholder='Package Name'
                                type="text"
                                value={packageName}
                                onChange={(e) => setPackageName(e.target.value)}
                                className="w-full px-3 py-2 border border-purple-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                            <input
                                placeholder='Amount Paid'
                                type="text"
                                value={amountPaid}
                                onChange={(e) => setAmountPaid(e.target.value)}
                                className="w-full px-3 py-2 border border-purple-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                            />
                            <button
                                type="submit"
                                className="w-full md:col-span-2 lg:col-span-4 px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
                            >
                                Submit Order
                            </button>
                        </form>
                    )}
                </div>

                {/* Table Section */}
                <div className="p-4 overflow-x-auto">
                    {orderData && orderData.length > 0 ? (
                        <table className="w-full border-collapse">
                            <thead className="bg-purple-100">
                                <tr>
                                    <th className="p-2 text-left border border-purple-300">Sr. No</th>
                                    <th className="p-2 text-left border border-purple-300">Order ID</th>
                                    <th className="p-2 text-left border border-purple-300">User ID</th>
                                    <th className="p-2 text-left border border-purple-300">Package Name</th>
                                    <th className="p-2 text-left border border-purple-300">Package ID</th>
                                    <th className="p-2 text-left border border-purple-300">Amount Paid</th>
                                    <th className="p-2 text-left border border-purple-300">Order Date</th>
                                    <th className="p-2 text-left border border-purple-300">Design Link</th>
                                    <th className="p-2 text-left border border-purple-300">Live Approval</th>
                                    <th className="p-2 text-left border border-purple-300">Design Approved</th>
                                    <th className="p-2 text-left border border-purple-300">Status</th>
                                    <th className="p-2 text-left border border-purple-300">Done</th>
                                    <th className="p-2 text-left border border-purple-300">Cancelled</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData.map((item, index) => (
                                    <tr
                                        key={index}
                                        className="hover:bg-purple-50 transition-colors"
                                    >
                                        <td className="p-2 border border-purple-300">{index + 1}</td>
                                        <td className="p-2 border border-purple-300">{item.order_id}</td>
                                        <td className="p-2 border border-purple-300">{item.user_id}</td>
                                        <td className="p-2 border border-purple-300">{item.package_name}</td>
                                        <td className="p-2 border border-purple-300">{item.package_id}</td>
                                        <td className="p-2 border border-purple-300">{item.amount_paid}</td>
                                        <td className="p-2 border border-purple-300">{item.order_date}</td>
                                        <td className="p-2 border border-purple-300">{item.design_link}</td>
                                        <td className="p-2 border border-purple-300">{item.desing_approval_live ? 'Yes' : 'No'}</td>
                                        <td className="p-2 border border-purple-300">{item.desing_approved ? 'Yes' : 'No'}</td>
                                        <td className="p-2 border border-purple-300">{item.status}</td>
                                        <td className="p-2 border border-purple-300">{item.done ? 'Yes' : 'No'}</td>
                                        <td className="p-2 border border-purple-300">{item.cancelled ? 'Yes' : 'No'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-center p-4 text-purple-600 bg-purple-50 rounded-md">
                            No orders found
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OrderTable
