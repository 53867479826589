import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { UserContext } from '../../context/UserContext';
let socket;

const TeamChat = () => {
    const { id } = useParams();
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]); // Added state to track messages
    const [messageList, setMessageList] = useState([]);

    const { user } = useContext(UserContext);
    const name = user.email;

    const chatBoxRef = useRef(null);
    // Scroll to the bottom when a new message is added
    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messageList]); // Run this effect whenever messageList changes


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_TEAM_MESSAGE, {
            params: { room: id }
        })
            .then(res => {
                setMessageList(res.data.data || []); // Initialize with existing messages
                console.log("Loaded messages:", res.data.data);
            })
            .catch(err => {
                console.error("Error fetching messages:", err);
            });
        // Disconnect previous socket if exists
        if (socket) {
            socket.disconnect();
        }

        // Establish new socket connection
        socket = io(process.env.REACT_APP_SOCKET_URL);
        socket.emit('join_room', `team${id}`);

        const handleMessage = (data) => {
            console.log("Message Received : ", data);
            // Update messages state when a new message is received
            setMessageList((prevMessages) => [...prevMessages, data]);
        }

        socket.on('receive_message', handleMessage)

        return () => {
            socket.off("receive_message", handleMessage);
            socket.disconnect(); // Explicitly disconnect socket on unmount
        };

    }, [id])

    const sendMessage = () => {
        const time = `${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}`;

        const messageData = {
            room: `team${id}`,
            author: name,
            message: message,
            time: time
        };

        if (socket && socket.connected) {
            socket.emit('send_message', messageData);

            // Optimistically update the message list
            setMessageList((prevMessages) => [...prevMessages, messageData]);

            axios.post(process.env.REACT_APP_SAVE_TEAM_MESSAGE, {
                messageObj: messageData,
                orderId: Number(id)
            })
                .then(res => {
                    console.log("Message saved successfully");
                    // Clear input after successful send
                    setMessage('');
                })
                .catch(err => {
                    console.error("Error saving team message:", err.response?.data || err.message);
                });
        } else {
            console.error("Socket not connected!");
        }
    };

    return (
        <div className='flex flex-col gap-5 p-4 md:p-8 max-w-4xl mx-auto'>
            <div ref={chatBoxRef} className='flex flex-col w-full h-[500px] md:h-[672px] overflow-y-auto bg-white rounded-lg shadow-md border border-gray-100 p-4'>
                {messageList.map((messageContent, index) => (
                    <div
                        key={index}
                        className={`flex w-full max-w-[70%] flex-col gap-1 mb-3 ${name === messageContent.author
                            ? "self-end items-end ml-auto"
                            : "self-start items-start mr-auto"
                            }`}
                    >
                        <p
                            className={`text-white w-fit max-w-full break-words p-3 text-base md:text-lg rounded-lg shadow-sm ${name === messageContent.author
                                ? "bg-[#703BE7]"
                                : "bg-[#00BA07] text-white"
                                }`}
                        >
                            {messageContent.message}
                        </p>
                        <p
                            className={`text-xs text-gray-500 ${name === messageContent.author
                                ? "text-[#703BE7]"
                                : "text-[#00BA07]"
                                }`}
                        >
                            {messageContent.time} {messageContent.author}
                        </p>
                    </div>
                ))}
            </div>

            {/* send message  */}
            <div className='flex flex-col md:flex-row gap-4 items-center'>
                <input
                    className='w-full md:w-3/4 h-16 md:h-20 rounded-lg px-4 py-2 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#703BE7]'
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder='Enter your message'
                    onKeyPress={(event) => {
                        if (event.key === "Enter") sendMessage();
                    }}
                />
                <button
                    onClick={sendMessage}
                    className='w-full md:w-[100px] h-16 md:h-20 bg-[#00BA07] text-white rounded-lg hover:bg-green-600 transition-colors'
                >
                    Send
                </button>
            </div>
        </div>
    )
}

export default TeamChat