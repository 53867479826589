import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserContext'
import axios from 'axios';

const History = () => {

    const { user } = useContext(UserContext);
    const userId = user.userId;
    const [cancelHistoryData, setCancelHistoryData] = useState([]);
    const [completedHistoryData, setCompletedHistoryData] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_DONE_HISTORY, { params: { userId: userId } })
            .then(res => {
                console.log("Completed data : ", res.data);
                setCompletedHistoryData(res.data.data)
            }).catch(err => {
                console.log("Error getting Done history : ", err)
            })

        axios.get(process.env.REACT_APP_API_CANCEL_HISTORY, { params: { userId: userId } })
            .then(res => {
                console.log("Cancelled data : ", res.data.data);
                setCancelHistoryData(res.data.data);
            }).catch(err => {
                console.log("Error getting Done history : ", err)
            })
    }, [user])


    return (
        <div class="bg-white min-h-screen py-10 px-4">
            <h1 class="text-4xl font-bold text-center text-[#703BE7] mb-10">Hello, I am History Pages</h1>



            <div class="flex flex-wrap gap-8 justify-center">




                {/* <!-- Cancelled orders -->  */}
                <div class="w-full md:w-1/2 bg-white shadow-md rounded-lg p-6">
                    <h2 class="text-2xl font-semibold text-[#703BE7] mb-4">Completed Projects</h2>
                    <div class="space-y-4">
                        {completedHistoryData && completedHistoryData.map((item, index) => {
                            const messageList = JSON.parse(item.message);
                            const answerList = JSON.parse(item.answers);
                            return (
                                <div key={index} class="bg-white p-4 border border-[#703BE7] rounded-lg">
                                    <p class="text-[#703BE7] font-medium">Order ID: {item.order_id}</p>
                                    <p class="text-gray-600">Package ID: {item.package_id}</p>
                                    <p class="text-gray-600">Package Name: {item.package_name}</p>
                                    <p class="text-gray-600">Amount Paid: ${item.amount_paid}</p>
                                    <p class="text-gray-600">Order Date: {item.order_date}</p>

                                    <div className='h-72 overflow-y-scroll bg-green-50 p-4 rounded-md border border-green-600'>
                                        <p class="text-green-600 font-semibold">Project details</p>
                                        {answerList && answerList.map((item, index) => (
                                            <div class="mt-2">
                                                <p class="text-gray-800 font-medium">Q. {index + 1} {item.question}</p>
                                                <p class="text-gray-600">Ans: {item.answers}</p>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='flex flex-col gap-5 p-4 md:p-8 max-w-4xl mx-auto'>
                                        <p class="text-[#703BE7] font-semibold">Chat history</p>
                                        <div className='flex flex-col w-full h-[500px] md:h-[572px] overflow-y-auto bg-white rounded-lg shadow-md border border-gray-100 p-4'>
                                            {messageList.map((message, index) => (
                                                <div
                                                    key={index}
                                                    className={`flex w-full max-w-[70%] flex-col gap-1 mb-3 ${message.author === user.email
                                                        ? "self-end items-end ml-auto" // User's message: right alignment
                                                        : "self-start items-start mr-auto" // Other users' messages: left alignment
                                                        }`}
                                                >
                                                    <p
                                                        className={`text-white w-fit max-w-full break-words p-3 text-base md:text-lg rounded-lg shadow-sm ${message.author === user.email
                                                            ? "bg-[#703BE7] text-white" // User's message style
                                                            : "bg-gray-200 text-gray-900" // Other users' message style
                                                            }`}
                                                    >
                                                        {message.message}
                                                    </p>
                                                    <p
                                                        className={`text-xs ${message.author === user.email
                                                            ? "text-[#703BE7]" // User's metadata style
                                                            : "text-gray-500" // Other users' metadata style
                                                            }`}
                                                    >
                                                        {message.time} - {message.author}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* <!-- Cancelled orders -->  */}
                <div class="w-full md:w-1/2 bg-white shadow-md rounded-lg p-6">
                    <h2 class="text-2xl font-semibold text-[#703BE7] mb-4">Cancelled Projects</h2>
                    <div class="space-y-4">
                        {cancelHistoryData && cancelHistoryData.map((item, index) => {
                            const messageList = JSON.parse(item.message);
                            const answerList = JSON.parse(item.answers);
                            return (
                                <div key={index} class="bg-white p-4 border border-[#703BE7] rounded-lg">
                                    <p class="text-[#703BE7] font-medium">Order ID: {item.order_id}</p>
                                    <p class="text-gray-600">Package ID: {item.package_id}</p>
                                    <p class="text-gray-600">Package Name: {item.package_name}</p>
                                    <p class="text-gray-600">Amount Paid: ${item.amount_paid}</p>
                                    <p class="text-gray-600">Order Date: {item.order_date}</p>

                                    <div className='h-72 overflow-y-scroll bg-green-50 p-4 rounded-md border border-green-600'>
                                        <p class="text-green-600 font-semibold">Project details</p>
                                        {answerList && answerList.map((item, index) => (
                                            <div class="mt-2">
                                                <p class="text-gray-800 font-medium">Q. {index + 1} {item.question}</p>
                                                <p class="text-gray-600">Ans: {item.answers}</p>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='flex flex-col gap-5 p-4 md:p-8 max-w-4xl mx-auto'>
                                        <p class="text-[#703BE7] font-semibold">Chat history</p>
                                        <div className='flex flex-col w-full h-[500px] md:h-[572px] overflow-y-auto bg-white rounded-lg shadow-md border border-gray-100 p-4'>
                                            {messageList.map((message, index) => (
                                                <div
                                                    key={index}
                                                    className={`flex w-full max-w-[70%] flex-col gap-1 mb-3 ${message.author === user.email
                                                        ? "self-end items-end ml-auto" // User's message: right alignment
                                                        : "self-start items-start mr-auto" // Other users' messages: left alignment
                                                        }`}
                                                >
                                                    <p
                                                        className={`text-white w-fit max-w-full break-words p-3 text-base md:text-lg rounded-lg shadow-sm ${message.author === user.email
                                                            ? "bg-[#703BE7] text-white" // User's message style
                                                            : "bg-gray-200 text-gray-900" // Other users' message style
                                                            }`}
                                                    >
                                                        {message.message}
                                                    </p>
                                                    <p
                                                        className={`text-xs ${message.author === user.email
                                                            ? "text-[#703BE7]" // User's metadata style
                                                            : "text-gray-500" // Other users' metadata style
                                                            }`}
                                                    >
                                                        {message.time} - {message.author}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>







            </div>
        </div>
    )
}

export default History
