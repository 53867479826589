import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Users, Mail, Calendar } from 'lucide-react';
import { toast } from 'sonner';

const Leads = () => {
    const [leadsData, setLeadsData] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_GET_LEADS)
            .then(res => {
                console.log(res.data.message, res.data.data);
                setLeadsData(res.data.data);
            }).catch(err => {
                console.log("Error getting leads data : ", err)
                toast.error("Error getting leads data")
            })
    }, [])

    return (
        <div className="min-h-screen bg-gradient-to-br from-[#703BE7]/10 to-[#703BE7]/20 p-4 sm:p-6 lg:p-8">
            <div className="max-w-6xl mx-auto">
                {/* Header */}
                <div className="bg-[#703BE7] rounded-t-2xl p-6 mb-6">
                    <h1 className="text-2xl sm:text-3xl font-bold text-white flex items-center">
                        <Users className="mr-3" size={32} />
                        See Leads Here
                    </h1>
                </div>

                {/* Table Container */}
                <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-[#703BE7]/10 border-b-2 border-[#703BE7]/20">
                                    <th className="px-6 py-4 text-left">
                                        <div className="flex items-center space-x-2">
                                            <span className="text-[#703BE7] font-semibold">#</span>
                                        </div>
                                    </th>
                                    <th className="px-6 py-4 text-left">
                                        <div className="flex items-center space-x-2">
                                            <Mail className="text-[#703BE7]" size={20} />
                                            <span className="text-[#703BE7] font-semibold">Email</span>
                                        </div>
                                    </th>
                                    <th className="px-6 py-4 text-left">
                                        <div className="flex items-center space-x-2">
                                            <Calendar className="text-[#703BE7]" size={20} />
                                            <span className="text-[#703BE7] font-semibold">Date</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-[#703BE7]/10">
                                {leadsData && leadsData.slice().reverse().map((item, index) => (
                                    <tr
                                        key={index}
                                        className="hover:bg-[#703BE7]/5 transition-colors"
                                    >
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className="font-medium text-gray-700">
                                                {index + 1}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                                <span className="text-gray-700">{item.email}</span>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className="text-gray-700">{item.date}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Empty State */}
                    {(!leadsData || leadsData.length === 0) && (
                        <div className="text-center py-12">
                            <p className="text-gray-500">No leads available</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Leads
