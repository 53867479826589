import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

const Chat = ({ socket, name, room }) => {
    const [currentMessage, setCurrentMessage] = useState("");
    const [messageList, setMessageList] = useState([]);

    const chatBoxRef = useRef(null);

    // Scroll to the bottom when a new message is added
    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messageList]); // Run this effect whenever messageList changes

    // Load existing messages from the server when the component mounts
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_GET_MESSAGES, {
            params: { room: room }
        })
            .then(res => {
                setMessageList(res.data.data || []); // Initialize with existing messages
                console.log("Loaded messages:", res.data.data);
            })
            .catch(err => {
                console.error("Error fetching messages:", err);
            });
    }, [room]);

    const sendMessage = () => {
        if (currentMessage.trim() !== "") {
            const messageData = {
                room: room,
                author: name,
                message: currentMessage.trim(),
                time: `${new Date().getHours()}:${new Date().getMinutes()}`,
            };

            // Emit the message via the socket
            socket.emit('send_message', messageData);

            // Update local message list
            setMessageList((list) => [...list, messageData]);

            // Save message to the backend
            axios.post(process.env.REACT_APP_API_SAVE_MESSAGE, {
                messageObj: messageData,
                orderId: room
            })
                .then(() => {
                    console.log("Message saved successfully");
                })
                .catch((err) => {
                    console.error("Error saving message:", err);
                });

            // Clear the input
            setCurrentMessage("");
        }
    };

    useEffect(() => {
        const handleMessage = (data) => {
            console.log("Message received:", data);
            setMessageList((list) => [...list, data]);
        };

        // Listen for incoming messages
        socket.on("receive_message", handleMessage);

        // Clean up to prevent duplicate listeners
        return () => {
            socket.off("receive_message", handleMessage);
        };
    }, [socket]);

    return (
        <div className='flex flex-col gap-5 p-4 md:p-8 max-w-4xl mx-auto'>
            <div ref={chatBoxRef} className='flex flex-col w-full h-[500px] md:h-[672px] overflow-y-auto bg-white rounded-lg shadow-md border border-gray-100 p-4'>
                {messageList.map((messageContent, index) => (
                    <div
                        key={index}
                        className={`flex w-full max-w-[70%] flex-col gap-1 mb-3 ${name === messageContent.author
                            ? "self-end items-end ml-auto"
                            : "self-start items-start mr-auto"
                            }`}
                    >
                        <p
                            className={`text-white w-fit max-w-full break-words p-3 text-base md:text-lg rounded-lg shadow-sm ${name === messageContent.author
                                ? "bg-[#703BE7]"
                                : "bg-[#00BA07] text-white"
                                }`}
                        >
                            {messageContent.message}
                        </p>
                        <p
                            className={`text-xs text-gray-500 ${name === messageContent.author
                                ? "text-[#703BE7]"
                                : "text-[#00BA07]"
                                }`}
                        >
                            {messageContent.time} {messageContent.author}
                        </p>
                    </div>
                ))}
            </div>

            {/* send message  */}
            <div className='flex flex-col md:flex-row gap-4 items-center'>
                <input
                    className='w-full md:w-3/4 h-16 md:h-20 rounded-lg px-4 py-2 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#703BE7]'
                    type="text"
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    placeholder='Enter your message'
                    onKeyPress={(event) => {
                        if (event.key === "Enter") sendMessage();
                    }}
                />
                <button
                    onClick={sendMessage}
                    className='w-full md:w-[100px] h-16 md:h-20 bg-[#00BA07] text-white rounded-lg hover:bg-green-600 transition-colors'
                >
                    Send
                </button>
            </div>
        </div>
    );
};

export default Chat;