import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Package, Mail, Calendar, DollarSign, Hash, Info } from 'lucide-react';
import { toast } from 'sonner';

const FunnelOrder = () => {
    const [orderData, setOrderData] = useState([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_FUNNEL_ORDER_DATA)
            .then(res => {
                setOrderData(res.data.data)
            }).catch(err => {
                console.log("Error getting order data")
                toast.error("Error getting Orders")
            })
    }, [])

    return (
        <div className="min-h-screen bg-gradient-to-br from-[#703BE7]/10 to-[#703BE7]/20 p-4 sm:p-6 lg:p-8">
            <div className="max-w-7xl mx-auto">
                {/* Header */}
                <div className="bg-[#703BE7] rounded-t-2xl p-6 mb-6">
                    <h1 className="text-2xl sm:text-3xl font-bold text-white flex items-center">
                        <Package className="mr-3" size={32} />
                        Funnel Order Page
                    </h1>
                </div>

                {/* Orders Grid */}
                <div className="space-y-6">
                    {orderData && orderData.slice().reverse().map((item, index) => {
                        const orderDetails = JSON.parse(item.info);
                        return (
                            <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden">
                                {/* Order Header */}
                                <div className="bg-[#703BE7]/10 p-4 border-l-4 border-[#703BE7]">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                                        <div className="flex items-center space-x-2">
                                            <Hash className="text-[#703BE7]" size={20} />
                                            <p className="text-gray-600">
                                                <span className="font-semibold text-[#703BE7]">#{index + 1}</span>
                                            </p>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Mail className="text-[#703BE7]" size={20} />
                                            <p className="text-gray-600 truncate">{item.email}</p>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Calendar className="text-[#703BE7]" size={20} />
                                            <p className="text-gray-600">{item.date}</p>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <DollarSign className="text-[#703BE7]" size={20} />
                                            <p className="text-gray-600">${item.amount}</p>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Info className="text-[#703BE7]" size={20} />
                                            <p className="text-gray-600 truncate">ID: {item.id}</p>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Package className="text-[#703BE7]" size={20} />
                                            <p className="text-gray-600 truncate">Package: {item.packge_id}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Order Details */}
                                <div className="p-4">
                                    <h3 className="text-lg font-semibold text-[#703BE7] mb-4">Order Details</h3>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        {orderDetails && orderDetails.map((orderItem, orderIndex) => (
                                            <div
                                                key={orderIndex}
                                                className="bg-[#703BE7]/5 rounded-lg p-4 hover:bg-[#703BE7]/10 transition-colors"
                                            >
                                                <p className="font-medium text-[#703BE7] mb-2">
                                                    {orderItem.question}
                                                </p>
                                                <p className="text-gray-600 pl-4 border-l-2 border-[#703BE7]">
                                                    {orderItem.answer}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default FunnelOrder
