import { Navigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext.js';
import { useContext, useEffect } from 'react';

export const AdminProtectedRoute = ({ children }) => {
    const { user } = useContext(UserContext);

    // Check for token in localStorage
    const token = localStorage.getItem('tj_token');

    // If there's no token, redirect to login immediately
    if (!token) {
        return <Navigate to="/login" replace />;
    }

    // While the user data is still loading
    if (user === null) {
        return <div>Loading...</div>; // or another loading indicator
    }

    // Check admin status after user is loaded
    return user && user.admin === 1 ? children : <Navigate to="/login" replace />;
};
