import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Questions = () => {
    const [question, setQuestion] = useState('');
    const [message, setMessage] = useState('');
    const [questionData, setQuestionData] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_API_ADD_QUESTION, { question: question })
            .then(res => {
                setMessage(res.data.message);
                window.location.reload();
            }).catch(err => {
                console.log(err);
                setMessage("Error : ", err)
            })
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_GET_ALL_QUESTIONS)
            .then(res => {
                setQuestionData(res.data.data);
                console.log(res.data.data)
                setMessage(res.message);
            }).catch(err => {
                console.log("Error getting questions : ", err)
                setMessage("Erro getting Question data")
            })
    }, [])

    const handleDelete = (id) => {
        alert("Are you sure you want to delelte this question")
        if (!id) {
            setMessage("Invalid question ID");
            return;
        }

        axios.delete(`${process.env.REACT_APP_API_DELETE_QUESTION}`, {
            params: { id }, // Pass id as query parameter
        })
            .then((res) => {
                setMessage(res.data.message);
                window.location.reload();
            })
            .catch((err) => {
                console.error("Error deleting question: ", err);
                setMessage("Error deleting question");
            });
    };

    const handleUpdate = (id) => {
        navigate(`/admin/question/update/${id}`)
    }


    return (
        <div class="bg-white min-h-screen flex flex-col items-center py-10">
            <h1 class="text-3xl font-bold text-[#703BE7] mb-6">Add and Manage Questions Here</h1>
            <h1 class="text-xl text-green-600 mb-4">{message}</h1>

            <form action="" onSubmit={handleSubmit} class="w-full max-w-lg flex flex-col items-center gap-4">
                <input
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#703BE7]"
                    placeholder="Type your question here..."
                />
                <button
                    type='submit'
                    class="bg-green-600 text-white px-6 py-2 rounded-md hover:bg-green-700 transition duration-300">
                    Add
                </button>
            </form>

            <div class="w-full max-w-2xl mt-8 space-y-4">
                {questionData && questionData.map((item, index) => (
                    <div
                        key={index}
                        class="flex justify-between items-center bg-gray-100 p-4 rounded-md shadow-md">
                        <p class="text-gray-800 font-medium">
                            {item.question_id} - {item.question}
                        </p>
                        <div class="flex space-x-4">
                            <button
                                onClick={() => handleDelete(item.question_id)}
                                class="bg-red-500 text-white px-4 py-1 rounded-md hover:bg-red-600 transition duration-300">
                                Delete
                            </button>
                            <button
                                onClick={() => handleUpdate(item.question_id)}
                                class="bg-[#703BE7] text-white px-4 py-1 rounded-md hover:bg-purple-700 transition duration-300">
                                Update
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Questions
