import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddBlog = () => {
    const [blogTitle, setBlogTitle] = useState('');
    const [subHeadings, setSubHeadings] = useState([]);

    //fetched blogs
    const [blogData, setBlogData] = useState([])
    const navigate = useNavigate();

    const handleUpdate = (id) => {
        navigate(`/blog/update/${id}`)
    }

    const handleAddSubheading = () => {
        setSubHeadings([...subHeadings, { heading: "", image: null, details: "" }]);
    };

    const handleRemoveSubheading = (index) => {
        setSubHeadings(subHeadings.filter((_, i) => i !== index));
    };

    const handleChange = (index, field, value) => {
        const updatedSubHeadings = subHeadings.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );
        setSubHeadings(updatedSubHeadings);
    };

    const handleBlogUpload = async () => {
        const formData = new FormData();
        formData.append("heading", blogTitle);

        // Append images and replace image field with the name
        const updatedSubHeadings = subHeadings.map((item, index) => {
            if (item.image) {
                formData.append("images", item.image);
                return { ...item, image: item.image.name };
            }
            return item;
        });

        formData.append("blogData", JSON.stringify(updatedSubHeadings));

        try {
            const response = await axios.post('http://localhost:8080/api/addblog', formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });
            console.log(response.data);
        } catch (err) {
            console.log("Error adding blog:", err);
        }

        window.location.reload();

    };

    useEffect(() => {
        axios.get('http://localhost:8080/api/getblog')
            .then(res => {
                console.log(res.data);
                setBlogData(res.data.data);
            }).catch(err => {
                console.log("Error getting the blog data : ", err)
            })
    }, [])


    const handleDelete = (id) => {
        alert("Are you sure you want to Delete this Blog ")
        axios.delete('http://localhost:8080/api/deleteblog', { params: { id: id } })
            .then(res => {
                console.log(res.data.message)
                window.location.reload();
            }).catch(err => {
                console.log("Error deleting BLOG : ", err)
            })
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen flex flex-col items-center">
            <div className="bg-white p-6 shadow-md rounded-md w-full max-w-2xl">
                <input
                    type="text"
                    placeholder="Blog Title"
                    className="w-full p-2 border rounded-md mb-4"
                    onChange={(e) => setBlogTitle(e.target.value)}
                    value={blogTitle}
                />

                {subHeadings.map((sub, index) => (
                    <div key={index} className="bg-gray-50 p-4 mb-4 rounded-md shadow-sm">
                        <input
                            type="text"
                            placeholder="Sub heading"
                            value={sub.heading}
                            onChange={(e) => handleChange(index, "heading", e.target.value)}
                            className="w-full p-2 border rounded-md mb-2"
                        />
                        <input
                            type="file"
                            onChange={(e) => handleChange(index, "image", e.target.files[0])}
                            className="w-full p-2 border rounded-md mb-2"
                        />
                        <textarea
                            placeholder="Details"
                            value={sub.details}
                            onChange={(e) => handleChange(index, "details", e.target.value)}
                            className="w-full p-2 border rounded-md mb-2"
                        ></textarea>

                        <button
                            onClick={() => handleRemoveSubheading(index)}
                            className="bg-red-500 text-white px-4 py-2 rounded-md"
                        >
                            - Remove subheading
                        </button>
                    </div>
                ))}

                <button
                    onClick={handleAddSubheading}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md w-full"
                >
                    + Add new subheading
                </button>
            </div>
            <button
                onClick={handleBlogUpload}
                className="bg-blue-500 text-white px-4 py-2 rounded-md w-full mt-4"
            >
                Upload Blog
            </button>
            <div className='w-full h-fit flex flex-col justify-center items-center pt-[100px]'>
                {/* blog container  */}
                <div className='flex flex-row justify-center items-center gap-6 flex-wrap'>

                    {
                        blogData && blogData.slice().reverse().map((item, index) => (
                            <div className='max-w-[535px] w-11/12 min-h-[286px] h-fit bg-black flex flex-col justify-between items-start pl-[35px] pr-[5px] p-[20px] cursor-pointer'>

                                <h2 className='franklinNine md:text-[24.29px] text-[18.29px] text-white w-full mt-[20px]'>{item.blog_heading}</h2>

                                <div>
                                    <p className='text-white text-[9.28px] mb-2'>{item.date}</p>
                                    <button onClick={() => handleUpdate(item.blog_id)} className='w-[117px] h-[33px] flex justify-center items-center bgPurple text-black text-[12.92px]'>Edit Blog</button>
                                    <button onClick={() => handleDelete(item.blog_id)} className='w-[117px] h-[33px] flex justify-center items-center bg-red-500 text-white text-[12.92px]'>Delete Blog</button>
                                </div>


                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
    );
};

export default AddBlog;
