import { useContext } from "react";
import { UserContext } from "./UserContext";
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
    const { user } = useContext(UserContext);

    const token = localStorage.getItem('tj_token');
    if (!token) {
        return <Navigate to="/login" />
    }


    // While user data is still loading
    if (user === null) {
        return <div>Loading...</div>; // Placeholder for a loading indicator
    }

    // Check user status after it has loaded
    return user ? children : <Navigate to="/login" replace />;
};
