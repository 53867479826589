import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const UpdateQuestion = () => {
    const { id } = useParams();
    const [question, setQuestion] = useState('');
    const [message, setMessage] = useState('');
    const [data, setData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            setMessage("Invalid question ID");
            return;
        }

        axios.get(process.env.REACT_APP_API_GET_ONE_QUESTION, {
            params: { id }, // Pass id as a query parameter
        })
            .then((res) => {
                if (res.data && res.data.data) {
                    setData(res.data.data); // Set the fetched question
                    setQuestion(res.data.data.question)
                    console.log(res.data.data)
                } else {
                    setMessage("No data found");
                }
            })
            .catch((err) => {
                console.error("Error getting question: ", err);
                setMessage("Error fetching question");
            });
    }, [id]);

    const handleUpdateSubmit = (e) => {
        e.preventDefault();
        axios.put(process.env.REACT_APP_API_UPDATE_QUESTION, { id: id, question: question })
            .then(res => {
                alert(res.data.message)
                navigate('/admin/questions')

            }).catch(err => {
                console.log("Error updating the Question : ", err)
            })
    }
    return (
        <div class="bg-white min-h-screen flex flex-col items-center py-10">
            <h1 class="text-3xl font-bold text-[#703BE7] mb-6">{message || "See here"}</h1>

            <form action="" onSubmit={handleUpdateSubmit} class="w-full max-w-lg flex flex-col items-center gap-4">
                <input
                    type="text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#703BE7]"
                    placeholder="Update your question here..."
                />
                <button
                    type='submit'
                    class="bg-green-600 text-white px-6 py-2 rounded-md hover:bg-green-700 transition duration-300">
                    Update
                </button>
            </form>
        </div>
    )
}

export default UpdateQuestion
