import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { toast } from 'sonner';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { user } = useContext(UserContext);
    useEffect(() => {
        if (user) {
            navigate('/dashboard');
        }
    }, [user])

    const handleLogin = (e) => {
        e.preventDefault();

        axios.post(process.env.REACT_APP_API_LOGIN, { email, password })
            .then(res => {
                toast(res.data.message);
                if (res.data.status === "Success") {
                    localStorage.setItem('tj_token', res.data.token); // Store token
                    window.location.reload();
                }
            })
            .catch(err => {
                console.error("Error during login:", err);
                toast.error("Error login in")
            });
    };

    return (
        <div className="w-full flex items-center justify-center min-h-screen bg-gray-900 text-white">
            <div className="w-full max-w-md p-8 bg-gray-800 rounded shadow-md">
                <h2 className="text-3xl font-bold text-center mb-6 text-purple-500">
                    Hey, I am the login page
                </h2>
                <form onSubmit={handleLogin} className="space-y-4">
                    <input
                        placeholder="Login email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-600 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                    />
                    <input
                        placeholder="Login password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-600 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                    />
                    <button
                        type="submit"
                        className="w-full py-2 bg-purple-500 text-black font-bold rounded hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
                    >
                        Login
                    </button>
                    <p className="mt-4 text-center text-gray-400">
                        Don't have an account?{" "}
                        <Link
                            to="/signup"
                            className="text-purple-500 font-medium hover:underline focus:outline-none focus:ring-2 focus:ring-purple-500"
                        >
                            Sign up
                        </Link>
                    </p>
                </form>
            </div>
        </div>

    );
};

export default Login;
