import React from 'react';

const Answers = (props) => {
    let answers = [];

    try {
        // Check if props.answers is already an array
        answers = Array.isArray(props.answers)
            ? props.answers
            : JSON.parse(props.answers);
    } catch (error) {
        console.error("Error parsing answers:", error.message);
        answers = []; // Fallback to an empty array if parsing fails
    }

    return (
        <div className="bg-white shadow-md rounded-lg p-6 space-y-4">
            {answers.length > 0 ? (
                answers.map((item, index) => (
                    <div
                        key={index}
                        className="bg-gray-50 p-4 rounded-lg border-l-4 border-[#703BE7] transition-all duration-300 hover:shadow-sm"
                    >
                        <p className="text-lg font-semibold text-gray-800 mb-2">
                            <span className="text-[#703BE7] mr-2">Q.{index + 1}:</span>
                            {item.question}
                        </p>
                        <p className="text-gray-600 pl-6 italic">
                            <span className="font-medium text-green-600 not-italic mr-2">Ans:</span>
                            {item.answers}
                        </p>
                    </div>
                ))
            ) : (
                <div className="text-center text-gray-500 italic p-4 bg-gray-50 rounded-lg">
                    No answers available.
                </div>
            )}
        </div>
    );
};

export default Answers;
