import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Answers from '../../pages/Answers';

const CompletedOrders = () => {
    const [orderData, setOrderData] = useState(null);
    const [isclicked, setIsClicked] = useState(false);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_COMPLETED_ORDERS)
            .then(res => {
                setOrderData(res.data.data);
                console.log(res.data.message);
                console.log(res.data.data);

            }).catch(err => {
                console.log("Error getting completed order data : ", err)
            })

        axios.get(process.env.REACT_APP_API_GET_USERS)
            .then(res => {
                console.log(res.data.data);
                setUserData(res.data.data)
            }).catch(err => {
                console.log("Error getting user data : ", err)
            })
    }, [])
    return (
        <div className="container mx-auto px-4 py-6">
            <h2 className="text-2xl font-bold text-[#703BE7] mb-6 text-center">See Your Completed Orders Here</h2>

            <div className="space-y-6">
                {orderData && orderData.slice().reverse().map((item, index) => {

                    // Find the corresponding user by user_id
                    const user = Array.isArray(userData) ? userData.find(user => user.user_id === item.user_id) : null;
                    const status = Number(item.status);
                    const answers = item.answers;


                    return (
                        <div
                            key={index}
                            className="bg-white shadow-xl rounded-2xl overflow-hidden border-2 border-[#703BE7] transition-all duration-300 hover:shadow-2xl"
                        >
                            <div className="bg-[#703BE7]/10 p-4">
                                <div className="flex flex-wrap justify-between items-center gap-4">
                                    {isclicked ? (
                                        <div className="flex items-center space-x-4">
                                            <button
                                                onClick={() => setIsClicked(false)}
                                                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                                            >
                                                Hide Answers
                                            </button>
                                            <Answers answers={answers} />
                                        </div>
                                    ) : (
                                        <button
                                            onClick={() => setIsClicked(true)}
                                            className="px-4 py-2 bg-[#703BE7] text-white rounded-lg hover:bg-purple-700 transition-colors"
                                        >
                                            Show answer
                                        </button>
                                    )}
                                </div>
                            </div>

                            <div className="p-6 grid md:grid-cols-2 gap-6">
                                <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                                    <div className="grid grid-cols-2 gap-2">
                                        <p className="text-sm text-gray-600">
                                            Order ID: <span className="text-[#703BE7] font-bold">{item.order_id}</span>
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            User ID: <span className="font-medium text-gray-800">{item.user_id}</span>
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            User Email: <span className="font-medium text-blue-500">{user ? user.user_email : "Email not found"}</span>
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Package Name: <span className="font-medium text-gray-800">{item.package_name}</span>
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Amount Paid: <span className="font-medium text-green-600">${item.amount_paid}</span>
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Order Date: <span className="font-medium text-gray-800">{item.order_date}</span>
                                        </p>
                                    </div>

                                    <div className="grid grid-cols-2 gap-2">
                                        <p className="text-sm text-gray-600">
                                            Design Approval Sent:{" "}
                                            <span className={`font-medium ${item.desing_approval_live === 0 ? "text-red-500" : "text-green-500"}`}>
                                                {item.desing_approval_live === 0 ? "NO" : "YES"}
                                            </span>
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Design Approved:{" "}
                                            <span className={`font-medium ${item.desing_approved === 0 ? "text-red-500" : "text-green-500"}`}>
                                                {item.desing_approved === 0 ? "NO" : "YES"}
                                            </span>
                                        </p>
                                        <p onClick={() => window.open(item.design_link, "_blank")} className="text-sm text-gray-600 cursor-pointer">
                                            Design Link:{" "}
                                            <span className={`font-medium text-blue-700`}>
                                                CLICK
                                            </span>
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Order Done:{" "}
                                            <span className={`font-medium ${item.done === 0 ? "text-orange-500" : "text-green-500"}`}>
                                                {item.done === 0 ? "Pending" : "Done"}
                                            </span>
                                        </p>
                                        <p className="text-sm text-gray-600">
                                            Order Cancelled:{" "}
                                            <span className={`font-medium ${item.cancelled === 0 ? "text-orange-500" : "text-red-500"}`}>
                                                {item.cancelled === 0 ? "Pending" : "Cancelled"}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

        </div>
    )
}

export default CompletedOrders
