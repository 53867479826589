import React, { useContext, useEffect, useState } from 'react';
import RazorpayPayment from './RazorpayPayment';
import { CreditCard, Mail, Lock, Sparkles, PhoneCallIcon, User } from 'lucide-react';
import Video from './Video';
import Heading from './Heading';
import { useNavigate } from 'react-router-dom';

const CheckoutPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');

    const secondText =
        `“ not SURE about What will happen after paying ₹51,215 ?“
dont worry , Watch this SHORT video`;
    const secondTextStyle = [
        { word: '₹51,215', color: 'text-green-500', italic: false },
        { word: 'SHORT', color: 'text-green-500', italic: true, fontWeight: 'font-thin' },
        { word: 'WEEKS', color: 'text-green-500', italic: false },

        { word: 'SURE', color: 'text-red-500', italic: false },
        // { word: 'working', color: 'text-blue-500', fontWeight: 'font-thin' },
        { word: 'results', color: 'text-green-500', italic: true, fontWeight: 'font-thin' },
    ];




    useEffect(() => {
        setEmail(localStorage.getItem('tj_email'));
    }, []);


    const offerItems = [
        { title: "Cancel Anytime", price: "₹29,806" },
        { title: "Hand-coded Responsive Website", price: "₹29,806" },
        { title: "Custom Design", price: "₹22,355" },
        { title: "Complete SEO", price: "₹17,884" },
        { title: "Backend CRM (Control & Change your own website)", price: "₹37,258" },
        { title: "24 hours CHAT support", price: "₹14,903" },
        { title: "Life-time 12 hours call support", price: "₹22,355" },
        { title: "Solving unique business problem with unique code solutions", price: "₹74,516" },
        { title: "Easy to use Software (Minimalist Backend + Decorative Frontend)", price: "₹44,709" },
        { title: "Staff Coaching", price: "₹11,923" },
        { title: "Owner Coaching", price: "₹14,903" },
        { title: "Family Coaching", price: "₹11,923" },
        { title: "Life-time hosting renewal (without any commission)", price: "₹14,903" },
        { title: "Complete Documentation (features, instruction, password)", price: "₹7,452" },
        { title: "Recorded Coaching", price: "₹10,432" },
        { title: "Full-Stack website features (user login, customization, etc)", price: "₹37,258" },
        { title: "Social Media Integration", price: "₹11,923" },
        { title: "Life-Time code Maintenance", price: "₹29,806" },
        { title: "3 - 4 Weeks of Delivery", price: "₹0" },
        { title: "See live Designing", price: "₹0" },
        { title: "Data Analytics Dashboard", price: "₹22,355" },
        { title: "Automated Email Notifications", price: "₹17,884" },
        { title: "API Integration (Payment Gateway, etc)", price: "₹14,903" },
        { title: "User Role Management System", price: "₹22,355" },
        { title: "First year of hosting from our side", price: "₹14,901" }
    ];



    return (
        <div className="min-h-screen bg-[#1E1E1E] flex flex-col flex-wrap items-center justify-center p-4 sm:p-6 lg:p-8 gap-5">

            <div className='flex md:flex-row flex-col md:justify-center  md:items-start items-center w-full h-fit md:gap-5 gap-2 md:mt-[50px] mt-[25px]'>

                <div className="w-full max-w-md bg-[#FFFFFF] rounded-2xl shadow-2xl overflow-hidden">
                    {/* Header Section */}
                    <div className="bg-[#00BA07] p-8 text-center relative overflow-hidden">
                        <div className="absolute top-0 left-0 w-full h-full opacity-10"></div>
                        <h1 className="text-2xl sm:text-3xl font-bold text-[#FFFFFF] flex flex-col items-center justify-center space-y-2">
                            <Sparkles className="mb-2 text-[#DBF516]" size={32} />
                            Checkout Form
                            <span className="text-sm font-normal text-[#D9D9D9]">
                                Full-Stack LUXURY
                            </span>
                            <span className="text-sm font-normal text-[#D9D9D9]">
                                Premium Development Package
                            </span>
                        </h1>
                    </div>

                    {/* Form Section */}
                    <div className="p-8 space-y-6">
                        <div className="space-y-2">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <Mail className="text-[#00BA07]" size={20} />
                                </div>
                                <input
                                    placeholder="Enter Email"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    value={email}
                                    className="w-full pl-10 pr-4 py-3 
                       border-2 border-[#00BA07]/30 
                       rounded-lg 
                       focus:outline-none 
                       focus:ring-2 focus:ring-[#00BA07] 
                       transition-all duration-300
                       placeholder-[#636363]"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <PhoneCallIcon className="text-[#00BA07]" size={20} />
                                </div>
                                <input
                                    placeholder="Enter Phone Number"
                                    type="text"
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                    value={phone}
                                    className="w-full pl-10 pr-4 py-3 
                       border-2 border-[#00BA07]/30 
                       rounded-lg 
                       focus:outline-none 
                       focus:ring-2 focus:ring-[#00BA07] 
                       transition-all duration-300
                       placeholder-[#636363]"
                                />
                            </div>
                        </div>
                        <div className="space-y-2">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <Lock className="text-[#00BA07]" size={20} />
                                </div>
                                <input
                                    placeholder="Enter Password"
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    value={password}
                                    className="w-full pl-10 pr-4 py-3 
                       border-2 border-[#00BA07]/30 
                       rounded-lg 
                       focus:outline-none 
                       focus:ring-2 focus:ring-[#00BA07] 
                       transition-all duration-300
                       placeholder-[#636363]"
                                />
                            </div>
                        </div>

                        {/* Payment Section */}
                        <div className="pt-4">
                            {email !== "" && password !== "" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? (
                                <div className="space-y-4 flex flex-col justify-center items-center">
                                    <div className="flex items-center justify-center text-sm text-[#636363]">
                                        <CreditCard className="text-[#00BA07] mr-2" size={20} />
                                        Secure Payment Gateway
                                    </div>
                                    <RazorpayPayment email={email} password={password} phone={phone} />
                                </div>
                            ) : (
                                <div className="bg-[#00BA07]/10 rounded-lg p-4 text-center">
                                    <p className="text-[#636363]">
                                        {email === "" || password === ""
                                            ? "Please enter your email and password to proceed with checkout"
                                            : "Please enter a valid email address"}
                                    </p>
                                </div>
                            )}

                        </div>

                        {/* Features List */}
                        <div className="pt-6 border-t border-[#00BA07]/20">
                            <div className="text-sm text-[#636363] space-y-2">
                                <p className="flex items-center">
                                    <Sparkles className="text-[#00BA07] mr-2" size={16} />
                                    Premium Full-Stack Development
                                </p>
                                <p className="flex items-center">
                                    <Sparkles className="text-[#00BA07] mr-2" size={16} />
                                    Priority Support & Consultation
                                </p>
                                <p className="flex items-center">
                                    <Sparkles className="text-[#00BA07] mr-2" size={16} />
                                    Advanced Features & Customization
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* hardest value display  */}
                <div className='md:w-1/2 w-11/12 h-fit flex flex-col justify-center items-center bg-white'>
                    {/* Green header div */}
                    <div className='w-full md:h-[180px] h-[103.36px] bg-[#00BA07] flex flex-col justify-end items-center mb-4'>
                        <p className='text-white md:text-2xl text-[11.19px] text-center md:w-2/3 w-11/12 franklinNine pb-6'>
                            Let me show you <span className='italic'>EVERYTHING</span> that you will get, when you order "Full-Stack LUXURY" TODAY !
                        </p>
                    </div>

                    {/* List div */}
                    <div className='flex flex-col justify-center items-center w-full h-fit flex-nowrap md:gap-4 gap-[8.4px]'>
                        {
                            offerItems && offerItems.map((item, index) => (
                                //single offer item
                                <div className='flex flex-row justify-center items-start w-full md:gap-6 gap-[28px]'>
                                    <div className='flex flex-row justify-center items-center md:gap-2 gap-1 w-[70%]'>
                                        <svg
                                            className="w-[12.69px] h-[12.69px] md:w-5 md:h-5"
                                            viewBox="0 0 27 27"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_175_308)">
                                                <path
                                                    d="M25.2052 1.1498L24.7945 0.864753L24.5094 1.27534L10.7985 21.018L1.91659 13.7668L1.52918 13.4505L1.21299 13.838L-0.387396 15.7993L-0.703421 16.1866L-0.316209 16.5027L11.1039 25.8262L11.5225 26.168L11.8307 25.7241L27.4107 3.28953L27.6959 2.87876L27.2851 2.59359L25.2052 1.1498Z"
                                                    fill="#00BA07"
                                                    stroke="#00BA07"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_175_308">
                                                    <rect width="27" height="27" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        <p className='text-[#636363] md:text-xl text-[11.19px] text-left md:w-1/2 w-[80%] franklinOne text-wrap'>
                                            {item.title}
                                        </p>
                                    </div>

                                    <p className='text-[#F04037] md:text-xl text-[11.19px] text-left w-[30%] franklinNine text-nowrap'>
                                        ( {item.price} Value )
                                    </p>
                                </div>
                            ))
                        }
                    </div>

                    {/* Total value section */}
                    <p className='franklinNine text-[22.27px] md:text-4xl md:mt-12 mt-[34.24px] tracking-wider text-[#F04037]'>
                        <span className='italic text-black'>Total Value : </span> ₹506,707
                    </p>

                    <p className='franklineThree text-[#1E1E1E] text-[22.27px] md:text-4xl md:mt-4 mt-2 w-full text-center'>
                        Automate your business TODAY for
                    </p>

                    {/* Price display */}
                    <p className='franklinNine text-[#00BA07] text-[122.73px] md:text-[200px] text-nowrap text-center italic leading-none my-4'>
                        ₹51,215
                    </p>

                    <p className='text-[#00BA07] md:text-xl text-[11.19px] text-center md:w-1/2 w-[80%] franklinNine text-wrap mt-2'>
                        <span className='text-[#F04037] italic'>CANCEL</span> anytime
                    </p>
                </div>
            </div>

            <Heading text={secondText} textStyles={secondTextStyle} margin={100} />
            <Video url="https://www.youtube.com/embed/1CetlupV7K4?si=cCA-zrl9uQ8oKTtC" lgwidth="w-1/2" />
        </div>

    );
};

export default CheckoutPage;
