import React from 'react'
import Video from '../../Component/Video'
import Heading from '../../Component/Heading'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Offer = () => {
    const navigate = useNavigate();
    const text =
        `“ How BUSINESS owners , just like you are completely AUTOMATING their business in JUST “
3 WEEKS !`;
    const textStyles = [
        { word: 'AUTOMATING', color: 'text-green-500', italic: false },
        { word: '3', color: 'text-green-500', italic: false },
        { word: 'WEEKS', color: 'text-green-500', italic: false },
        { word: 'just', color: 'text-red-500', italic: false },
        { word: 'like', color: 'text-red-500', italic: false },
        { word: 'you', color: 'text-red-500', italic: false },
        // { word: 'working', color: 'text-blue-500', fontWeight: 'font-thin' },
        { word: 'results', color: 'text-green-500', italic: true, fontWeight: 'font-thin' },
    ];
    const secondText =
        `“ not SURE about What will happen after paying ₹51,215 ?“
dont worry , Watch this SHORT video`;
    const secondTextStyle = [
        { word: '₹51,215', color: 'text-green-500', italic: false },
        { word: 'SHORT', color: 'text-green-500', italic: true, fontWeight: 'font-thin' },
        { word: 'WEEKS', color: 'text-green-500', italic: false },

        { word: 'SURE', color: 'text-red-500', italic: false },
        // { word: 'working', color: 'text-blue-500', fontWeight: 'font-thin' },
        { word: 'results', color: 'text-green-500', italic: true, fontWeight: 'font-thin' },
    ];


    const offerItems = [
        { title: `Hand-coded Responsive Website`, price: 24 },
        { title: `Custom Design`, price: 24 },
        { title: `Complete SEO`, price: 24 },
        { title: `Backend CRM (Control & Change your own website)`, price: 24 },
        { title: `24 hours CHAT support`, price: 24 },
        { title: `Life-time 12 hours call support`, price: 24 },
        { title: `Solving unique business problem with unique code solutions`, price: 24 },
        { title: `Easy to use Software (Minimilist Backend + Decorative Frontend)`, price: 24 },
        { title: `Staff Coaching`, price: 24 },
        { title: `Owner Coaching`, price: 24 },
        { title: `Family Coaching`, price: 24 },
        { title: `Life-time hosting renewal (without any commission)`, price: 24 },
        { title: `Complete Documentation (features, instruction, password)`, price: 24 },
        { title: `Recorded Coaching`, price: 24 },
        { title: `Full-Stack website features (user login, customization, etc)`, price: 24 },
        { title: `Social Media Integration`, price: 24 },
        { title: `Life-Time code Maintenance`, price: 24 },
        { title: `3 - 4 Weeks of Delivery`, price: 24 },
        { title: `See live Designing`, price: 24 },
        { title: `Cancel Anytime`, price: 24 },
    ];


    return (
        <>
            <Helmet>
                {/* 🏆 High-Converting Title */}
                <title>TechJoy Full Stack Luxury | Business Automation - Work Less, Earn More!</title>

                {/* 🎯 Power-Packed Meta Description */}
                <meta
                    name="description"
                    content="Automate your business with TechJoy’s Full Stack Luxury package. Work less, earn more & run your business on autopilot using custom-coded business automation solutions. 🚀"
                />

                {/* 🔥 Keyword-Rich Meta Tags */}
                <meta
                    name="keywords"
                    content="business automation, automate my business, TechJoy, TechJoy full stack luxury, custom CRM, AI automation, workflow automation, SaaS solutions, grow business without hiring, business efficiency tools, work less earn more, business automation India"
                />

                {/* 🚀 Open Graph for Facebook, LinkedIn, WhatsApp */}
                <meta property="og:title" content="Full Stack Luxury | Automate Your Business with TechJoy 🚀" />
                <meta property="og:description" content="Get a custom-coded automation system for your business & remove repetitive tasks. Work less, earn more, and grow your business without hiring extra staff!" />
                <meta property="og:image" content="https://techjoy.in/path-to-fullstackluxury-thumbnail.jpg" />
                <meta property="og:url" content="https://techjoy.in/full-stack-luxury" />
                <meta property="og:type" content="website" />

                {/* 🐦 Twitter SEO Optimization */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Automate Your Business | TechJoy Full Stack Luxury 🚀" />
                <meta name="twitter:description" content="Stop wasting time on repetitive tasks! Automate your business in just 3 weeks & increase profits. Learn more now!" />
                <meta name="twitter:image" content="https://techjoy.in/path-to-fullstackluxury-thumbnail.jpg" />

                {/* 📌 Canonical URL to Avoid Duplicate Content Issues */}
                <link rel="canonical" href="https://techjoy.in/full-stack-luxury" />

                {/* 🔎 Local SEO (For Google Business Listings) */}
                <meta name="geo.region" content="IN" />
                <meta name="geo.placename" content="India" />
                <meta name="geo.position" content="19.0760;72.8777" />
                <meta name="ICBM" content="19.0760, 72.8777" />

                {/* 📈 Google Structured Data (Schema Markup) for Rich Results */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Product",
                        "name": "Full Stack Luxury – Business Automation Package",
                        "description": "Work less, earn more, and automate your business in just 3 weeks using a custom-built web application. No need to hire extra staff!",
                        "brand": {
                            "@type": "Brand",
                            "name": "TechJoy"
                        },
                        "offers": {
                            "@type": "Offer",
                            "url": "https://techjoy.in/full-stack-luxury",
                            "priceCurrency": "INR",
                            "availability": "InStock",
                            "seller": {
                                "@type": "Organization",
                                "name": "TechJoy",
                                "url": "https://techjoy.in/"
                            }
                        }
                    })}
                </script>
            </Helmet>
            <div className='flex flex-col w-full h-fit justify-center items-center bg-[#1E1E1E] gap-[100px] pb-[200px]'>

                <div className='flex flex-col justify-center items-center gap-[10px] mt-[100px]'>

                    <h2 className='text-white md:text-[29.3px] text-[11.19px] text-center'>"WORK less - EARN more - HIRE none"</h2>

                    <Heading text={text} textStyles={textStyles} margin={10} />
                </div>

                <Video url="https://www.youtube.com/embed/UTGgkXOsE1s?si=mt6FFlHkIGfzVpNX" heading="make sure the SOUND is turned ON" lgwidth="w-2/3" />

                <div className='flex flex-col justify-center items-center md:gap-[50px] gap-[20px]'>
                    <p className='franklinThree text-white md:text-[29.3px] text-[11.19px] text-center md:w-2/3 w-11/12'>Cut off your business working hours & get rid of doing same booring task again and again
                        while increasing your income WITHOUT hiring any additional staff</p>

                    <p className='text-white md:text-[29.3px] text-[11.19px] text-center md:w-2/3 w-11/12 franklinNine tracking-widest'>by USING a WEB-APPLICATION tailor-made for your BUSINESS</p>
                </div>

                {/* button one  */}
                <button onClick={() => navigate('/full-stack-luxury/checkout')} className='md:w-1/3 w-4/5 h-fit md:pt-[18px] mdLpb-[18px] bg-[#00BA07] franklinNine text-white md:text-[29.3px] text-[13.77px]'>🛒 Yes, Place my order now 😀 , <br /> before it's too late</button>
                <p onClick={() => window.location.href = "https://wa.me/919860852466"} className='text-white md:text-[29.3px] text-[11.19px] text-center md:w-2/3 w-11/12 franklinNine tracking-widest cursor-pointer'>OR call on +919860852466, for Non-Indians call on Whatsapp</p>
            </div>
        </>

    )
}

export default Offer
