import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Heading from '../../Component/Heading.js'
import Video from '../../Component/Video.js';
import { Helmet } from 'react-helmet'

const Reward = () => {
    const [isAccess, setIsAccess] = useState(false);
    const [dateTime, setDateTime] = useState('');
    // const [finalAnswer, setFinalAnswer] = useState({});
    //

    const questions = [
        'What is your name',
        'What is your company Name',
        'What is your moms name'
    ]


    const [answers, setAnswers] = useState(
        questions.map((question) => ({ question, answer: "" }))
    );
    const consultationStatus = localStorage.getItem('tj_consultation')


    useEffect(() => {
        const accessStatus = localStorage.getItem('reward_acces');
        if (!accessStatus) {
            setIsAccess(false);
        } else {
            setIsAccess(true)
        }
    }, [])


    function convertDateTime(input) {
        const dateTime = new Date(input);
        const date = dateTime.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
        const hours = dateTime.getHours();
        const minutes = String(dateTime.getMinutes()).padStart(2, "0");

        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12; // Convert to 12-hour format

        const time = `${formattedHours}:${minutes} ${period}`;
        return `Date : ${date} Time: ${time}`;
    }

    const formattedDateTime = convertDateTime(dateTime);

    const email = localStorage.getItem('tj_email');

    const handleConsultation = () => {
        axios.post(process.env.REACT_APP_API_SAVE_CONSULTATION, { email: email, info: formattedDateTime })
            .then(res => {
                alert(res.data.message);
                localStorage.setItem('tj_consultation', true);
                window.location.reload();
            }).catch(err => {
                console.log("Error booking consultation : ", err.data.response)
            })
        console.log(formattedDateTime);
        console.log(email);
    }

    const handleChange = (index, value) => {
        const updatedAnswers = [...answers];
        updatedAnswers[index].answer = value; // Update the specific answer
        setAnswers(updatedAnswers);
    };

    // const handleWebsite = () => {
    //     // Check if all answers are filled
    //     const allAnswersFilled = answers.every(({ answer }) => answer.trim() !== "");

    //     if (!allAnswersFilled) {
    //         alert("Please fill the Information before submitting");
    //     } else {
    //         setIsSubmit(true);
    //     }
    // };

    // const payment = () => {
    //     axios.post(process.env.REACT_APP_API_FUNNEL_CHECKOUT, { packageId: 1, bumped: bumped, email: email, info: answers })
    //         .then(res => {
    //             if (res.data.url) {
    //                 window.location.href = res.data.url;
    //             } else {
    //                 alert("Oops, Payment failed")
    //             }
    //         }).catch(err => {
    //             console.log("Error making payment : ", err)
    //         })
    // }

    // const razorpayPayment = (e) => {
    //     axios.post(process.env.REACT_APP_API_RAZORPAY_FUNNEL_CHEKCOUT, { packageId: 1, bumped: bumped, email: email, info: answers })
    //         .then(res => {
    //             console.log("Order created succesfully ORDER ID : ", res.data.order.id);

    //             //create a payload object for chekcout flow 
    //             const options = {
    //                 "key": process.env.REACT_APP_RAZORPAY_KEY,
    //                 "name": "Funnel payment",
    //                 "description": "Test payment",
    //                 "image": 'https://example.com/your_logo',
    //                 "order_id": res.data.order.id,
    //                 "handler": function (response) {
    //                     window.location.reload();
    //                 },
    //                 "prefill": {
    //                     "name": "Lalit", //frontend
    //                     "email": "lalit@gmail.com", //frontend
    //                     "contact": "9000090000", //frontend
    //                 },
    //                 "notes": {
    //                     "address": "Razorpay Corporate Office",
    //                 },
    //                 "theme": {
    //                     "color": "#3399cc",
    //                 }
    //             }

    //             const rzp1 = new window.Razorpay(options);

    //             //funtin to capture payment failure
    //             rzp1.on('payment.failed', function (response) {
    //                 alert("Payment failed . Please try again");
    //                 console.error("Payment failed details : ", response)
    //             })

    //             //open the rzorypay
    //             rzp1.open();
    //         }).catch(err => {
    //             console.log("Error Getting order : ", err)
    //         })
    // }

    // useEffect(() => {
    //     if (bumped) {
    //         setPrice(37);
    //     } else {
    //         setPrice(8);
    //     }
    // }, [bumped])


    const text = "Congratulations, You have unlocked All the secrets to business Automation";

    const textStyles = [
        { word: 'Congratulations,', color: 'text-green-500', italic: false },
    ];



    return (
        <>
            <Helmet>
                {/* 🎯 High-Impact Title for Maximum Clicks */}
                <title>TechJoy Rewards | Unlock Your FREE Business Automation Secrets Now! 🚀</title>

                {/* 📝 Click-Worthy Meta Description */}
                <meta
                    name="description"
                    content="Congrats! You’ve unlocked your exclusive TechJoy rewards. Get instant access to our FREE video guides & 1-on-1 consultation to automate your business. Work less, earn more!"
                />

                {/* 🔥 Keyword-Rich Meta Tags for High Search Rankings */}
                <meta
                    name="keywords"
                    content="TechJoy rewards, free business automation, free business consultation, automate my business, free business growth guides, full stack business automation, AI for business, business automation tools, TechJoy free consultation, work less earn more, scale business without hiring, passive income, SaaS business automation"
                />

                {/* 🚀 Open Graph for Facebook, LinkedIn, WhatsApp */}
                <meta property="og:title" content="TechJoy Rewards | Your Business Automation Secrets are Here! 🎁" />
                <meta property="og:description" content="Your rewards are ready! Get free video guides & an exclusive 1-on-1 consultation to automate your business. Work less, earn more & run your business on autopilot!" />
                <meta property="og:image" content="https://techjoy.in/path-to-rewards-thumbnail.jpg" />
                <meta property="og:url" content="https://techjoy.in/rewards" />
                <meta property="og:type" content="website" />

                {/* 🐦 Twitter SEO Optimization */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="TechJoy Rewards 🎁 | Your Free Business Automation Tools are Here!" />
                <meta name="twitter:description" content="Get your free video guides & consultation to automate your business. Work less, earn more & scale faster!" />
                <meta name="twitter:image" content="https://techjoy.in/path-to-rewards-thumbnail.jpg" />

                {/* 📌 Canonical URL to Avoid Duplicate Content Issues */}
                <link rel="canonical" href="https://techjoy.in/rewards" />

                {/* 🔎 Local SEO (Google Business Listings) */}
                <meta name="geo.region" content="IN" />
                <meta name="geo.placename" content="India" />
                <meta name="geo.position" content="19.0760;72.8777" />
                <meta name="ICBM" content="19.0760, 72.8777" />

                {/* 📈 Google Structured Data (Schema Markup) for Rich Results */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "TechJoy Rewards – Free Business Automation Secrets",
                        "description": "Access your free TechJoy rewards! Get instant access to our exclusive business automation video guides & a private 1-hour consultation to scale your business without hiring extra staff.",
                        "url": "https://techjoy.in/rewards",
                        "publisher": {
                            "@type": "Organization",
                            "name": "TechJoy",
                            "url": "https://techjoy.in/"
                        },
                        "offers": {
                            "@type": "Offer",
                            "url": "https://techjoy.in/rewards",
                            "price": "0.00",
                            "priceCurrency": "INR",
                            "availability": "InStock",
                            "seller": {
                                "@type": "Organization",
                                "name": "TechJoy",
                                "url": "https://techjoy.in/"
                            }
                        }
                    })}
                </script>
            </Helmet>
            <div className='flex flex-col justify-center items-center w-full h-fit bg-[#1E1E1E]'>

                <Heading text={text} textStyles={textStyles} margin={100} />

                <div className='md:mt-[200px] mt-[100px]'>
                    <p className='franklinNine md:text-[26.5px] text-[16.8] text-white tracking-wide '>1. <span className='text-[#00BA07]'>FREE</span> video guide, revealing the secrets to automate a complex business</p>
                    <Video url="https://www.youtube.com/embed/t-fwEoYkfKQ?si=Si0fhrKEuspAmcX3" heading="Make sure the SOUND is turned ON" lgwidth="w-full" />
                </div>

                <div className='md:mt-[200px] mt-[100px]'>

                    <p className='franklinNine md:text-[26.5px] text-[16.8] text-white tracking-wide '>1. <span className='text-[#00BA07]'>FREE</span> video guide, secrets to automate a simple business</p>
                    <Video url="https://www.youtube.com/embed/r8vXcY79LYY?si=n7-GZZ_8z5IhWeSu" heading="Make sure the SOUND is turned ON" lgwidth="w-full" />
                </div>

                {
                    isAccess ? (
                        <div>
                            {
                                consultationStatus ? (
                                    <div className='flex flex-col justify-center items-center w-full py-8 md:py-12 max-w-6xl mx-auto px-4'>
                                        <div className='bg-gray-900/50 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-[#00BA07]/20 shadow-lg'>
                                            <p className='text-[#E1FF00] font-semibold md:text-xl text-sm text-center max-w-xl mx-auto tracking-wide'>
                                                Consultation Already Booked
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='flex flex-col justify-center items-center w-full md:mt-40 mt-20 gap-4 px-4 max-w-6xl mx-auto'>
                                        <p className='text-white tracking-wide text-center md:text-3xl text-xl font-semibold'>
                                            1. <span className='text-[#00BA07]'>FREE</span> 1:1 (one on one) Live 1 HOUR consultation on how your business can be automated
                                        </p>

                                        <p className='text-[#E1FF00] font-normal md:text-lg text-sm max-w-2xl text-center'>
                                            Select Date and Time for 1 : 1 consultation
                                        </p>

                                        <input
                                            className='w-full max-w-xl h-14 md:h-20 bg-white rounded-xl md:rounded-2xl 
                   md:text-2xl text-base text-[#3b3b3b] outline-none border-2 border-transparent 
                   hover:border-[#00BA07] focus:border-[#00BA07] transition-all duration-200 px-4'
                                            onChange={(e) => { setDateTime(e.target.value) }}
                                            type="datetime-local"
                                        />

                                        <button
                                            className='text-white bg-[#00BA07] rounded-xl md:rounded-2xl w-full max-w-xl 
                   py-4 md:py-5 px-6 md:text-2xl text-base font-semibold 
                   hover:bg-[#009606] transition-all duration-200 transform hover:scale-[1.02] 
                   active:scale-[0.98] shadow-lg hover:shadow-xl'
                                            onClick={handleConsultation}
                                        >
                                            Book my Consultation
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    ) : (
                        <div>
                            NO goodies found please enter your email to get those goodies
                            'techjoy.in/automate-step-1'
                        </div>
                    )
                }
            </div >
        </>

    )
}

export default Reward
