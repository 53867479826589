import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Temp from '../../pages/Temp';
import { useNavigate } from 'react-router-dom';
import Answers from '../../pages/Answers';
import { UserContext } from '../../context/UserContext';
import { toast } from 'sonner';

const Order = () => {
    const [orderData, setOrderData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [designLink, setDesignLink] = useState('');
    const [isclicked, setIsClicked] = useState(false);
    const navigate = useNavigate();

    const items = ["Meeting", "Home page design approval", "Designing", "Development", "Demo + Coaching", "Website live", "Document Received", "Done"];

    const { user } = useContext(UserContext);


    useEffect(() => {
        axios.get(process.env.REACT_APP_API_SEE_ORDER)
            .then(res => {
                console.log(res.data.data);
                setOrderData(res.data.data);

            }).catch(err => {
                console.log("Error getting order data : ", err);
            })

        axios.get(process.env.REACT_APP_API_GET_USERS)
            .then(res => {
                console.log(res.data.data);
                setUserData(res.data.data)
            }).catch(err => {
                console.log("Error getting user data : ", err)
            })
    }, [])

    const updateStatus = (status, orderId) => {
        axios.put(process.env.REACT_APP_API_UPDATE_STATUS, { status: status, orderId: orderId })
            .then(res => {
                toast(res.data.data.message);
                window.location.reload();
            }).catch(err => {
                console.log("Erro updating status : ", err)
            })
    }



    const completeOrder = (orderId) => {
        axios.put(process.env.REACT_APP_API_COMPLETE_ORDER_DESIGN, { orderId: orderId })
            .then(res => {
                console.log(res.data.message);
                toast.success(res.data.message);
                window.location.reload();
            }).catch(err => {
                console.log("Error cancelling the order : ", err)
            })
    }

    //send order id with it 
    const sendDesignApproval = (orderId) => {
        axios.put(process.env.REACT_APP_API_SEND_APPROVAL_DESIGN, { orderId: orderId })
            .then(res => {
                console.log(res.data.message);
                window.location.reload();
            }).catch(err => {
                console.log("Error sending approval : ", err);
                toast.error("Error sending approval")
            })
    }

    const handleDesignLink = (designLink, orderId) => {
        axios.put(process.env.REACT_APP_API_SEND_DESIGN_LINK, { designLink: designLink, orderId: orderId })
            .then(res => {
                console.log(res.data.message);
                window.location.reload();
            }).catch(err => {
                console.log("Error sending design link : ", err);
                toast.error("Error submiting design link");
            })
    }

    return (
        <div className="container mx-auto px-4 py-6">
            <h2 className="text-2xl font-bold text-[#703BE7] mb-6 text-center">See Your Orders Here</h2>
            {
                user.role === 'owner' &&
                <div className="space-y-6">
                    {orderData && orderData.slice().reverse().map((item, index) => {

                        // Find the corresponding user by user_id
                        const user = Array.isArray(userData) ? userData.find(user => user.user_id === item.user_id) : null;
                        const status = Number(item.status);
                        const answers = item.answers;


                        return (
                            <div
                                key={index}
                                className="bg-white shadow-xl rounded-2xl overflow-hidden border-2 border-[#703BE7] transition-all duration-300 hover:shadow-2xl"
                            >
                                <div className="bg-[#703BE7]/10 p-4">
                                    <div className="flex flex-wrap justify-between items-center gap-4">
                                        <div className="flex space-x-4">
                                            <button
                                                onClick={() => navigate(`/admin/orders/chat/${item.order_id}`)}
                                                className="px-4 py-2 bg-[#703BE7] text-white rounded-lg hover:bg-purple-700 transition-colors"
                                            >
                                                Chat with client
                                            </button>
                                            <button
                                                onClick={() => navigate(`/admin/orders/teamchat/${item.order_id}`)}
                                                className="px-4 py-2 bg-[#703BE7] text-white rounded-lg hover:bg-purple-700 transition-colors"
                                            >
                                                Team Chat
                                            </button>
                                            <button
                                                onClick={() => completeOrder(item.order_id)}
                                                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                                            >
                                                Done
                                            </button>
                                            <button
                                                onClick={() => sendDesignApproval(item.order_id)}
                                                className="px-4 py-2 bg-[#703BE7] text-white rounded-lg hover:bg-purple-700 transition-colors"
                                            >
                                                Send design approval
                                            </button>
                                        </div>

                                        {isclicked ? (
                                            <div className="flex items-center space-x-4">
                                                <button
                                                    onClick={() => setIsClicked(false)}
                                                    className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                                                >
                                                    Hide Answers
                                                </button>
                                                <Answers answers={answers} />
                                            </div>
                                        ) : (
                                            <button
                                                onClick={() => setIsClicked(true)}
                                                className="px-4 py-2 bg-[#703BE7] text-white rounded-lg hover:bg-purple-700 transition-colors"
                                            >
                                                Show answer
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <div className="p-6 grid md:grid-cols-2 gap-6">
                                    <div className="space-y-4">
                                        <div className="flex space-x-2">
                                            <input
                                                placeholder='Paste design link'
                                                type="text"
                                                value={designLink}
                                                onChange={(e) => setDesignLink(e.target.value)}
                                                className="flex-grow px-3 py-2 border border-[#703BE7] rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                                            />
                                            <button
                                                onClick={() => handleDesignLink(designLink, item.order_id)}
                                                type='submit'
                                                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                                            >
                                                Send Design Link
                                            </button>
                                        </div>

                                        <div className="grid grid-cols-3 gap-4">
                                            {items && items.map((newItem, index) => (
                                                <div
                                                    key={index}
                                                    className='flex flex-col items-center gap-[30px] w-[100px]'
                                                >
                                                    <div
                                                        onClick={() => updateStatus((index + 1), item.order_id)}
                                                        className={`w-[28px] h-[28px] rounded-full cursor-pointer transition-colors ${index < status ? "bg-green-500" : "bg-red-500"
                                                            }`}
                                                    />
                                                    <p className='w-full h-fit text-left text-black text-wrap'>{newItem}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                                        <div className="grid grid-cols-2 gap-2">
                                            <p className="text-sm text-gray-600">
                                                Order ID: <span className="text-[#703BE7] font-bold">{item.order_id}</span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                User ID: <span className="font-medium text-gray-800">{item.user_id}</span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                User Email: <span className="font-medium text-blue-500">{user ? user.user_email : "Email not found"}</span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Package Name: <span className="font-medium text-gray-800">{item.package_name}</span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Amount Paid: <span className="font-medium text-green-600">${item.amount_paid}</span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Order Date: <span className="font-medium text-gray-800">{item.order_date}</span>
                                            </p>
                                        </div>

                                        <div className="grid grid-cols-2 gap-2">
                                            <p className="text-sm text-gray-600">
                                                Design Approval Sent:{" "}
                                                <span className={`font-medium ${item.desing_approval_live === 0 ? "text-red-500" : "text-green-500"}`}>
                                                    {item.desing_approval_live === 0 ? "NO" : "YES"}
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Design Approved:{" "}
                                                <span className={`font-medium ${item.desing_approved === 0 ? "text-red-500" : "text-green-500"}`}>
                                                    {item.desing_approved === 0 ? "NO" : "YES"}
                                                </span>
                                            </p>
                                            <p onClick={() => window.open(item.design_link, "_blank")} className="text-sm text-gray-600 cursor-pointer">
                                                Design Link:{" "}
                                                <span className={`font-medium text-blue-700`}>
                                                    CLICK
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Order Done:{" "}
                                                <span className={`font-medium ${item.done === 0 ? "text-orange-500" : "text-green-500"}`}>
                                                    {item.done === 0 ? "Pending" : "Done"}
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Order Cancelled:{" "}
                                                <span className={`font-medium ${item.cancelled === 0 ? "text-orange-500" : "text-red-500"}`}>
                                                    {item.cancelled === 0 ? "Pending" : "Cancelled"}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            }

            {/* designer role  */}


            {
                user.role === 'designer' &&
                <div className="space-y-6">
                    {orderData && orderData.map((item, index) => {

                        // Find the corresponding user by user_id
                        const user = Array.isArray(userData) ? userData.find(user => user.user_id === item.user_id) : null;
                        const status = Number(item.status);
                        const answers = item.answers;


                        return (
                            <div
                                key={index}
                                className="bg-white shadow-xl rounded-2xl overflow-hidden border-2 border-[#703BE7] transition-all duration-300 hover:shadow-2xl"
                            >
                                <div className="bg-[#703BE7]/10 p-4">
                                    <div className="flex flex-wrap justify-between items-center gap-4">
                                        <div className="flex space-x-4">
                                            <button
                                                onClick={() => navigate(`/admin/orders/chat/${item.order_id}`)}
                                                className="px-4 py-2 bg-[#703BE7] text-white rounded-lg hover:bg-purple-700 transition-colors"
                                            >
                                                Chat with client
                                            </button>
                                            <button
                                                onClick={() => navigate(`/admin/orders/teamchat/${item.order_id}`)}
                                                className="px-4 py-2 bg-[#703BE7] text-white rounded-lg hover:bg-purple-700 transition-colors"
                                            >
                                                Team Chat
                                            </button>
                                            <button
                                                onClick={() => sendDesignApproval(item.order_id)}
                                                className="px-4 py-2 bg-[#703BE7] text-white rounded-lg hover:bg-purple-700 transition-colors"
                                            >
                                                Send design approval
                                            </button>
                                        </div>

                                        {isclicked ? (
                                            <div className="flex items-center space-x-4">
                                                <button
                                                    onClick={() => setIsClicked(false)}
                                                    className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                                                >
                                                    Hide Answers
                                                </button>
                                                <Answers answers={answers} />
                                            </div>
                                        ) : (
                                            <button
                                                onClick={() => setIsClicked(true)}
                                                className="px-4 py-2 bg-[#703BE7] text-white rounded-lg hover:bg-purple-700 transition-colors"
                                            >
                                                Show answer
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <div className="p-6 grid md:grid-cols-2 gap-6">
                                    <div className="space-y-4">
                                        <div className="flex space-x-2">
                                            <input
                                                placeholder='Paste design link'
                                                type="text"
                                                value={designLink}
                                                onChange={(e) => setDesignLink(e.target.value)}
                                                className="flex-grow px-3 py-2 border border-[#703BE7] rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                                            />
                                            <button
                                                onClick={() => handleDesignLink(designLink, item.order_id)}
                                                type='submit'
                                                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                                            >
                                                Send Design Link
                                            </button>
                                        </div>

                                        <div className="grid grid-cols-3 gap-4">
                                            {items && items.map((newItem, index) => (
                                                <div
                                                    key={index}
                                                    className='flex flex-col items-center gap-[30px] w-[100px]'
                                                >
                                                    <div
                                                        className={`w-[28px] h-[28px] rounded-full cursor-pointer transition-colors ${index < status ? "bg-green-500" : "bg-red-500"
                                                            }`}
                                                    />
                                                    <p className='w-full h-fit text-left text-black text-wrap'>{newItem}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                                        <div className="grid grid-cols-2 gap-2">
                                            <p className="text-sm text-gray-600">
                                                Order ID: <span className="text-[#703BE7] font-bold">{item.order_id}</span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                User ID: <span className="font-medium text-gray-800">{item.user_id}</span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Order Date: <span className="font-medium text-gray-800">{item.order_date}</span>
                                            </p>
                                        </div>

                                        <div className="grid grid-cols-2 gap-2">
                                            <p onClick={() => window.open(item.design_link, "_blank")} className="text-sm text-gray-600 cursor-pointer">
                                                Design Link:{" "}
                                                <span className={`font-medium text-blue-700`}>
                                                    CLICK
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Design Approval Sent:{" "}
                                                <span className={`font-medium ${item.desing_approval_live === 0 ? "text-red-500" : "text-green-500"}`}>
                                                    {item.desing_approval_live === 0 ? "NO" : "YES"}
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Design Approved:{" "}
                                                <span className={`font-medium ${item.desing_approved === 0 ? "text-red-500" : "text-green-500"}`}>
                                                    {item.desing_approved === 0 ? "NO" : "YES"}
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Order Done:{" "}
                                                <span className={`font-medium ${item.done === 0 ? "text-orange-500" : "text-green-500"}`}>
                                                    {item.done === 0 ? "Pending" : "Done"}
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Order Cancelled:{" "}
                                                <span className={`font-medium ${item.cancelled === 0 ? "text-orange-500" : "text-red-500"}`}>
                                                    {item.cancelled === 0 ? "Pending" : "Cancelled"}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

            }
            {/* Developer role  */}


            {
                user.role === 'developer' &&
                <div className="space-y-6">
                    {orderData && orderData.map((item, index) => {

                        // Find the corresponding user by user_id
                        const user = Array.isArray(userData) ? userData.find(user => user.user_id === item.user_id) : null;
                        const status = Number(item.status);
                        const answers = item.answers;


                        return (
                            <div
                                key={index}
                                className="bg-white shadow-xl rounded-2xl overflow-hidden border-2 border-[#703BE7] transition-all duration-300 hover:shadow-2xl"
                            >
                                <div className="bg-[#703BE7]/10 p-4">
                                    <div className="flex flex-wrap justify-between items-center gap-4">
                                        <div className="flex space-x-4">
                                            <button
                                                onClick={() => navigate(`/admin/orders/teamchat/${item.order_id}`)}
                                                className="px-4 py-2 bg-[#703BE7] text-white rounded-lg hover:bg-purple-700 transition-colors"
                                            >
                                                Team Chat
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-6 grid md:grid-cols-2 gap-6">
                                    <div className="space-y-4">
                                        <div className="grid grid-cols-3 gap-4">
                                            {items && items.map((newItem, index) => (
                                                <div
                                                    key={index}
                                                    className='flex flex-col items-center gap-[30px] w-[100px]'
                                                >
                                                    <div
                                                        className={`w-[28px] h-[28px] rounded-full cursor-pointer transition-colors ${index < status ? "bg-green-500" : "bg-red-500"
                                                            }`}
                                                    />
                                                    <p className='w-full h-fit text-left text-black text-wrap'>{newItem}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                                        <div className="grid grid-cols-2 gap-2">
                                            <p className="text-sm text-gray-600">
                                                Order ID: <span className="text-[#703BE7] font-bold">{item.order_id}</span>
                                            </p>

                                            <p className="text-sm text-gray-600">
                                                Order Date: <span className="font-medium text-gray-800">{item.order_date}</span>
                                            </p>
                                        </div>

                                        <div className="grid grid-cols-2 gap-2">
                                            <p onClick={() => window.open(item.design_link, "_blank")} className="text-sm text-gray-600 cursor-pointer">
                                                Design Link:{" "}
                                                <span className={`font-medium text-blue-700`}>
                                                    CLICK
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Design Approval Sent:{" "}
                                                <span className={`font-medium ${item.desing_approval_live === 0 ? "text-red-500" : "text-green-500"}`}>
                                                    {item.desing_approval_live === 0 ? "NO" : "YES"}
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Design Approved:{" "}
                                                <span className={`font-medium ${item.desing_approved === 0 ? "text-red-500" : "text-green-500"}`}>
                                                    {item.desing_approved === 0 ? "NO" : "YES"}
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Order Done:{" "}
                                                <span className={`font-medium ${item.done === 0 ? "text-orange-500" : "text-green-500"}`}>
                                                    {item.done === 0 ? "Pending" : "Done"}
                                                </span>
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Order Cancelled:{" "}
                                                <span className={`font-medium ${item.cancelled === 0 ? "text-orange-500" : "text-red-500"}`}>
                                                    {item.cancelled === 0 ? "Pending" : "Cancelled"}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

            }

            <button
                onClick={() => navigate(`/admin/orders/allchat/${1}`)}
                className="px-4 py-2 bg-[#703BE7] text-white rounded-lg hover:bg-purple-700 transition-colors"
            >
                Global Chat
            </button>



        </div>
    )
}

export default Order
